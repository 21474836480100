import { hasValue } from '@fastre/core/src/helperFunctions/array'
import { condSwitch } from '@fastre/core/src/helperFunctions/ramda'
import { getUserFullName } from '@fastre/core/src/helperFunctions/string'
import { formatAddress } from '@fastre/core/src/schemas/generic'
import { FormType } from '@fastre/core/src/schemas/rei'
import { ListingTenantInfoSchema, UpdateSaleListingSchema } from '@fastre/core/src/schemas/saleListing'
import { getVendorName } from '@fastre/core/src/schemas/vendor'
import {
    AddRounded,
    AttachFileOutlined,
    Check,
    DeleteRounded,
    MoreVertRounded,
    MoveToInbox,
    RefreshRounded,
    SendRounded,
    UploadFileRounded,
    Visibility,
} from '@mui/icons-material'
import {
    Box,
    Button,
    Dropdown,
    IconButton,
    Link,
    ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem,
    Stack,
    Step,
    StepIndicator,
    Stepper,
    Tooltip,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useConjunctionalAgenciesApi, useListingType, useOrgDetailsApi, useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import PdfModal from 'components/pdfModal'
import { useShowSnack } from 'components/snackbar'
import { always, cond, prop, propEq, T } from 'ramda'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { reiConnectionUrl, useReiSession } from 'users/reiConnection'
import { useListingContext } from '../listingProvider'
import { AbandonFormModal } from './abandonFormModal'
import AddAttachmentModal from './addAttachmentModal'
import UploadFormModal from './uploadFormModal'

const OptionalTooltip = ({ title, show, children }) =>
    show ? <Tooltip title={title}>{children}</Tooltip> : children

enum FormStage {
    'none' = 0,
    'created',
    'sent',
    'signedBy1',
    'completed',
    'manual',
}

interface FormStepperProps {
    title: string
    formType: FormType
    createDisabled: boolean
    validate: () => Promise<boolean> | boolean
    preCreate: () => Promise<boolean> | boolean
    allowAttachments?: boolean
}

const FormStepper = ({
    title,
    formType,
    createDisabled,
    validate,
    preCreate,
    allowAttachments,
}: FormStepperProps) => {
    const { setValue } = useFormContext<UpdateSaleListingSchema>()
    const { listing, setListing } = useListingContext()
    const api = useApi()
    const { user } = useUserData()
    const listingType = useListingType()
    const usersApi = useUsersApi()
    const reiSession = useReiSession()
    const form = listing[formType]
    const showSnack = useShowSnack()
    const isMobile = true // useIsMobile()
    const orgDetailsApi = useOrgDetailsApi()
    const conjunctionalAgenciesApi = useConjunctionalAgenciesApi()

    const [viewLoading, setViewLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showAbandonFormDialog, setShowAbandonFormDialog] = useState<FormType | undefined>()
    const [showUploadModal, setShowUploadModal] = useState<FormType | undefined>()
    const [previewPdf, setPreviewPdf] = useState<string>()
    const [showAttachFileModal, setShowAttachFileModal] = useState(false)
    const [showCreateFormModal, setShowCreateFormModal] = useState(false)

    /*const ocrResult =
        form?.ocr && previewPdf && user.lastName == 'Bleier'
            ? Object.entries(formType == 'agentAppointmentForm' ? Form6ocr : ContractOcr)
                  .map(([pageIndex, ocrFields]) => ({
                      [Number(pageIndex)]: (ocrFields.schema as CoreOcrSchema[])
                          .map(ocrField => {
                              const valueFromResults = form?.ocr[pageIndex]?.[ocrField.name]

                              if (!valueFromResults) {
                                  console.log('not found', ocrField.name)
                                  return undefined
                              }

                              return {
                                  name: ocrField.name,
                                  ...compareOcr(ocrField.compare)(listing, valueFromResults),
                              }
                          })
                          .filter(hasValue),
                  }))
                  .reduce(reduceToObject, {})
            : undefined*/

    /*
            const ocrResult: OcrComparisonResult[] | undefined =
        form?.ocr_gpt && previewPdf && user.lastName == 'Bleier'
            ? [
                  ...Object.entries(form?.ocr_gpt),
                  ...Object.entries(form?.ocr_gpt?.client1),
                  ...Object.entries(form?.ocr_gpt?.client2),
              ]
                  .map(([key, val]) => {
                      console.log({ key, val })

                      return [key, val]
                  })
                  //.filter(([key, val]) => val != null && val.boundingBox != null)
                  .map(([key, val]) => {
                      if (Array.isArray(val)) {
                          return val.map((v, i) => {
                              return {
                                  name: key + i,
                                  boundingBox: v?.boundingBox,
                                  expectedValue: v?.value ?? '--',
                                  success: true,
                              }
                          })
                      } else {
                          return {
                              name: key,
                              boundingBox: val?.boundingBox,
                              expectedValue: val?.value ?? '--',
                              success: true,
                          }
                      }
                  })
                  .flat()
                  .filter(x => x.boundingBox != null)
            : undefined
            */

    const agent = usersApi.data?.find(user => user.userId == listing.agents[0].userId)

    const conjunctional = agent?.conjunctionalAgencyId
        ? conjunctionalAgenciesApi.data?.find(x => x.conjunctionalAgencyId == user.conjunctionalAgencyId)
        : undefined

    /*const ocrExpectedResults =
        listing.listingId && orgDetailsApi.data && agent && import.meta.env.DEV
            ? getOcrDataMap(formType, listing, orgDetailsApi.data, agent, conjunctional)
            : undefined*/

    const ocrResult = undefined
    /*form?.azureOcr && previewPdf && user.lastName == 'Bleier' && ocrExpectedResults
            ? Object.entries(form.azureOcr)
                  .filter(
                      ([key, val]) => val.value != undefined, // && val.kind == 'string') || val.kind == 'selectionMark',
                  )
                  .map(([fieldName, ocrFields]) => {
                      const transformedValue = ocrExpectedResults.transformations[fieldName]
                          ? ocrExpectedResults.transformations[fieldName](ocrFields.value)
                          : ocrFields.value

                      const success = transformedValue == ocrExpectedResults.results[fieldName]

                      return {
                          name: fieldName,
                          success,
                          expectedValue: !success
                              ? ocrExpectedResults.results[fieldName]
                                  ? ocrExpectedResults.results[fieldName] // + ' - ' + transformedValue
                                  : 'not found'
                              : undefined,
                          pageNumber: ocrFields.boundingRegions![0].pageNumber,
                          boundingBox: {
                              top: ocrFields.boundingRegions![0].polygon[0].y,
                              left: ocrFields.boundingRegions![0].polygon[0].x,
                              width:
                                  ocrFields.boundingRegions![0].polygon[1].x -
                                  ocrFields.boundingRegions![0].polygon[0].x,
                              height:
                                  ocrFields.boundingRegions![0].polygon[2].y -
                                  ocrFields.boundingRegions![0].polygon[0].y,
                          },
                      }
                  })
            : undefined*/

    if (ocrResult) {
        console.log('ocrResult', ocrResult)
    }

    const signerTypes = condSwitch<FormType | 'contractForm', string[]>(
        [
            ['agentAppointmentForm', ['Vendor', 'Agent']],
            ['contractForm', ['Buyer', 'Vendor']],
            ['tenantConsentOpenHomeForm', ['Tenant', 'Agent']],
            ['intentToSellForm', ['Managing Agent', 'Selling Agent']],
            ['entryNoticeForm', ['Managing Agent']],
        ],
        ['', ''],
    )(formType)

    const agentSigner = usersApi.maybeData
        .map(users => {
            const agent = users.find(user => user.userId == listing.agents[0].userId)

            return agent
                ? ([
                      {
                          email: agent.email,
                          name: agent.firstName + ' ' + agent.lastName,
                      },
                  ] as any)
                : []
        })
        .orSome([])

    if (listing.occupantStatus != 'investment') {
        return null
    }

    const getUserFromId = (userId: string) =>
        usersApi.maybeData.orUndefined()?.find(user => user.userId == userId)

    const managingAgent = listing.tenancyInfo.managingAgent
        ? listing.tenancyInfo.managingAgent.type == 'internal'
            ? {
                  email: getUserFromId(listing.tenancyInfo.managingAgent.userId)?.email ?? '',
                  name: getUserFromId(listing.tenancyInfo.managingAgent.userId)
                      ? getUserFullName(getUserFromId(listing.tenancyInfo.managingAgent.userId)!)
                      : '',
              }
            : {
                  email: listing.tenancyInfo.managingAgent.email ?? '',
                  name: listing.tenancyInfo.managingAgent.name,
              }
        : {
              email: '',
              name: '',
          }

    const signer1Emails = condSwitch<FormType, Array<{ name: string; email: string }>>(
        [
            [
                'agentAppointmentForm',
                (listing.vendors ?? []).map(vendor => ({
                    email: vendor.email as string,
                    name: getVendorName(vendor),
                })),
            ],
            [
                'tenantConsentOpenHomeForm',
                (listing as ListingTenantInfoSchema).tenancyInfo?.tenants
                    ?.filter(t => t.email != undefined)
                    .map(tenant => ({
                        email: tenant.email!,
                        name: tenant.name ?? '',
                    }))
                    .filter(hasValue) ?? [],
            ],
            ['entryNoticeForm', [managingAgent]],
            ['intentToSellForm', [managingAgent]],
        ],
        [],
    )(formType)

    const signer2Emails = condSwitch<FormType, Array<{ name: string; email: string }>>(
        [
            ['agentAppointmentForm', agentSigner],
            ['tenantConsentOpenHomeForm', agentSigner],
            ['intentToSellForm', agentSigner],
        ],
        [],
    )(formType)

    const getSignersFromContact = (contacts: { email: string; name: string }[]) =>
        contacts
            .map(({ name, email }) =>
                form?.signatureRecipients?.find(
                    recipient => recipient.email == email || recipient.name == name,
                ),
            )
            .filter(hasValue)

    const signers1 = getSignersFromContact(signer1Emails)
    const signers2 = getSignersFromContact(signer2Emails)

    const signers2Done = signers2.length > 0 && signers2.every(signer => signer.status == 'completed')

    const formStage =
        form == undefined
            ? FormStage.none
            : cond([
                  [propEq('formStatus', 'manual upload'), always(FormStage.manual)],
                  [propEq('formStatus', 'completed'), always(FormStage.completed)],
                  [propEq('formStatus', 'draft'), always(FormStage.created)],
                  [propEq('formStatus', 'finalised'), always(FormStage.created)],
                  [
                      () => signers1.length >= 1 && signers1.every(x => x.status == 'completed'),
                      always(FormStage.signedBy1),
                  ],
                  [propEq('formStatus', 'sent'), always(FormStage.sent)],
                  [T, always(FormStage.none)],
              ])(form)

    const secondSignatureRequired = signerTypes.length > 1

    return (
        <>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 6 }}>
                <Typography
                    level="h4"
                    sx={{ m: 0 }}
                >
                    {title}
                </Typography>
                {user.reiConnectionToken != undefined && (
                    <>
                        <Dropdown>
                            <MenuButton
                                slots={{ root: IconButton }}
                                slotProps={{ root: { size: 'sm' } }}
                            >
                                <MoreVertRounded fontSize="small" />
                            </MenuButton>
                            <Menu>
                                {formStage == FormStage.created && (
                                    <MenuItem onClick={() => setShowAttachFileModal(true)}>
                                        <ListItemDecorator>
                                            <AttachFileOutlined />
                                        </ListItemDecorator>
                                        Attach File
                                    </MenuItem>
                                )}
                                {formStage != FormStage.none && (
                                    <MenuItem onClick={() => setShowAbandonFormDialog(formType)}>
                                        <ListItemDecorator>
                                            <DeleteRounded />
                                        </ListItemDecorator>
                                        Abandon Form
                                    </MenuItem>
                                )}
                                {formStage >= FormStage.sent && formStage != FormStage.manual && (
                                    <MenuItem
                                        onClick={async () => {
                                            const newListing = await api
                                                .post(
                                                    `listing/${listingType}/${listing.listingId}/rei/${formType}/refresh`,
                                                )
                                                .then(prop('data'))
                                            setListing(newListing)
                                        }}
                                    >
                                        <ListItemDecorator>
                                            <RefreshRounded />
                                        </ListItemDecorator>
                                        Refresh
                                    </MenuItem>
                                )}
                                {formStage < FormStage.completed && (
                                    <MenuItem onClick={() => setShowUploadModal(formType)}>
                                        <ListItemDecorator>
                                            <UploadFileRounded />
                                        </ListItemDecorator>
                                        Upload Completed Form
                                    </MenuItem>
                                )}
                            </Menu>
                        </Dropdown>
                        <Box sx={{ width: 44 }} />
                        {formStage == FormStage.none && (
                            <Box>
                                <Button
                                    loading={loading}
                                    onClick={async () => {
                                        /*setLoading(true)
                                        const pass = await preCreate()
                                        if (pass) {
                                            const { listing: updatedListing, url } = await api
                                                .post(
                                                    `listing/${listingType}/${listing.listingId}/rei/${formType.toLocaleLowerCase()}/create`,
                                                )
                                                .then(prop('data'))
                                            setListing(updatedListing)
                                            window.open(url, '_blank')
                                        }
                                        //console.log('PASS', pass)
                                        setLoading(false)*/

                                        if (await validate()) {
                                            if (allowAttachments) {
                                                setShowCreateFormModal(true)
                                            } else {
                                                setLoading(true)
                                                const pass = await preCreate()
                                                if (pass) {
                                                    const { listing: updatedListing, url } = await api
                                                        .post(
                                                            `listing/${listingType}/${listing.listingId}/rei/${formType.toLocaleLowerCase()}/create`,
                                                        )
                                                        .then(prop('data'))
                                                    setListing(updatedListing)
                                                    window.open(url, '_blank')
                                                }
                                                setLoading(false)
                                            }
                                        }
                                    }}
                                    startDecorator={<AddRounded />}
                                    disabled={createDisabled}
                                >
                                    Create Form
                                </Button>
                            </Box>
                        )}
                        {formStage !== FormStage.none && (
                            <Box>
                                <Button
                                    variant="outlined"
                                    loading={viewLoading || (reiSession != undefined && reiSession.isNone())}
                                    onClick={async () => {
                                        try {
                                            setViewLoading(true)
                                            const data = await api
                                                .get(
                                                    `listing/${listingType}/${listing.listingId}/rei/${formType.toLocaleLowerCase()}`,
                                                )
                                                .then(prop('data'))
                                            if (data.url) {
                                                if (data.url.includes('.pdf')) {
                                                    setPreviewPdf(data.url)
                                                } else {
                                                    window.open(data.url, '_blank')
                                                }
                                            } else {
                                                console.log('data', data)
                                            }
                                        } catch (e: any) {
                                            showSnack(
                                                e.response?.data?.message ?? 'Error viewing form',
                                                'danger',
                                            )
                                        } finally {
                                            setViewLoading(false)
                                        }
                                    }}
                                    startDecorator={<Visibility />}
                                    disabled={reiSession == undefined}
                                >
                                    View Form
                                </Button>
                            </Box>
                        )}
                        {formStage == FormStage.created && (
                            <OptionalTooltip
                                show={
                                    reiSession != undefined &&
                                    reiSession.map(x => !x.docusignConnected).orSome(false)
                                }
                                title="Connect your DocuSign account to Realworks in order to send forms"
                            >
                                <Box>
                                    <Button
                                        loading={loading || (reiSession != undefined && reiSession.isNone())}
                                        onClick={async () => {
                                            setLoading(true)
                                            try {
                                                const { listing: updatedListing } = await api
                                                    .post(
                                                        `listing/${listingType}/${listing.listingId}/rei/${formType.toLocaleLowerCase()}/send`,
                                                    )
                                                    .then(prop('data'))
                                                setListing(updatedListing)
                                                showSnack('Form Sent', 'success')
                                            } catch (e: any) {
                                                showSnack(
                                                    e.response?.data?.message ?? 'Error sending form',
                                                    'danger',
                                                )
                                            } finally {
                                                setLoading(false)
                                            }
                                        }}
                                        startDecorator={<SendRounded />}
                                        disabled={
                                            reiSession == undefined ||
                                            reiSession.map(x => !x.docusignConnected).orSome(false)
                                        }
                                    >
                                        Send Form
                                    </Button>
                                </Box>
                            </OptionalTooltip>
                        )}
                    </>
                )}
            </Box>
            {user.reiConnectionToken == undefined && (
                <Typography sx={{ mt: 2 }}>
                    <Link href={reiConnectionUrl(user)}>Connect</Link> your Realworks account to create, send
                    and view forms
                </Typography>
            )}
            {formStage == FormStage.manual && (
                <Typography
                    level="body-lg"
                    sx={{ mt: 1 }}
                >
                    Form manually uploaded
                </Typography>
            )}
            {user.reiConnectionToken != undefined && formStage != FormStage.manual && (
                <>
                    <Stepper
                        //orientation={isMobile ? 'vertical' : 'horizontal'}
                        orientation="vertical"
                        sx={{ width: '100%', mt: 2 }}
                    >
                        {/* Create */}
                        <Step
                            indicator={
                                <StepIndicator
                                    variant={FormStage.created <= formStage ? 'solid' : 'soft'}
                                    color={FormStage.created <= formStage + 1 ? 'primary' : 'neutral'}
                                >
                                    {formStage != FormStage.none ? <Check /> : undefined}
                                </StepIndicator>
                            }
                            sx={{
                                '&::after': {
                                    ...(FormStage.created <= formStage && {
                                        //stage !== FormStage.complete &&
                                        bgcolor: 'primary.solidBg',
                                    }),
                                },
                            }}
                        >
                            <Typography>Create</Typography>
                        </Step>

                        {/* Send */}
                        <Step
                            indicator={
                                <StepIndicator
                                    variant={FormStage.sent <= formStage ? 'solid' : 'soft'}
                                    color={FormStage.sent <= formStage + 1 ? 'primary' : 'neutral'}
                                >
                                    {formStage >= FormStage.sent ? <Check /> : undefined}
                                </StepIndicator>
                            }
                            sx={{
                                '&::after': {
                                    ...(formStage >= FormStage.sent && {
                                        bgcolor: 'primary.solidBg',
                                    }),
                                },
                            }}
                        >
                            <Typography>Send</Typography>
                        </Step>

                        {/* First Signature */}
                        <Step
                            indicator={
                                <StepIndicator
                                    variant={FormStage.signedBy1 <= formStage ? 'solid' : 'soft'}
                                    color={FormStage.signedBy1 <= formStage + 1 ? 'primary' : 'neutral'}
                                >
                                    {formStage >= FormStage.signedBy1 ? (
                                        <Check />
                                    ) : signers1.length > 0 &&
                                      signers1.every(signer => signer.status == 'delivered') ? (
                                        <MoveToInbox />
                                    ) : undefined}
                                </StepIndicator>
                            }
                            sx={
                                secondSignatureRequired
                                    ? {
                                          '&::after': {
                                              ...(FormStage.signedBy1 <= formStage && {
                                                  bgcolor: 'primary.solidBg',
                                              }),
                                          },
                                      }
                                    : {
                                          // hide line
                                          '&::after': {
                                              display: 'none',
                                          },
                                      }
                            }
                        >
                            <Stack>
                                <Typography>{signerTypes[0]} Signature(s)</Typography>
                            </Stack>
                        </Step>

                        {/*Second Signature*/}
                        {secondSignatureRequired && (
                            <Step
                                indicator={
                                    <StepIndicator
                                        variant={
                                            FormStage.completed <= formStage || signers2Done
                                                ? 'solid'
                                                : 'soft'
                                        }
                                        color={
                                            FormStage.completed <= formStage + 1 || signers2Done
                                                ? 'primary'
                                                : 'neutral'
                                        }
                                    >
                                        {formStage == FormStage.completed || signers2Done ? (
                                            <Check />
                                        ) : undefined}
                                    </StepIndicator>
                                }
                            >
                                <Stack>
                                    <Typography>{signerTypes[1]} Signature(s)</Typography>
                                </Stack>
                            </Step>
                        )}
                    </Stepper>
                    {/*formStage == FormStage.sent &&
                        <Box>
                            {form?.signatureRecipients?.map(recipient =>
                                <Box key={recipient.name} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Typography>
                                        {recipient.name}: {recipient.status}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    */}
                </>
            )}
            <AbandonFormModal
                formType={showAbandonFormDialog!}
                open={showAbandonFormDialog != undefined}
                close={() => setShowAbandonFormDialog(undefined)}
                setValue={setValue}
            />
            <UploadFormModal
                formType={showUploadModal!}
                open={showUploadModal != undefined}
                close={() => setShowUploadModal(undefined)}
            />
            <AddAttachmentModal
                formType={formType}
                open={showAttachFileModal}
                onClose={() => setShowAttachFileModal(false)}
                onAccept={async s3Keys => {
                    if (s3Keys.length == 0) {
                        const updatedListing = await api
                            .post(
                                `/listing/${listingType}/${listing.listingId}/forms/${formType}/attachfiles`,
                                {
                                    s3Keys,
                                },
                            )
                            .then(prop('data'))
                        setListing(updatedListing)
                    }
                    setShowAttachFileModal(false)
                }}
            />
            <PdfModal
                title={title}
                pdf={previewPdf}
                onClose={() => setPreviewPdf(undefined)}
                fileName={
                    listing.listingAddress ? `${formatAddress(listing.listingAddress)}_${formType}.pdf` : ''
                }
            />
            {/*
            <CreateFormModal
                open={showCreateFormModal}
                onClose={() => setShowCreateFormModal(false)}
                formType={formType}
                preCreate={preCreate}
                formName={title}
            />*/}
            <AddAttachmentModal
                formType={formType}
                open={showCreateFormModal}
                onClose={() => setShowCreateFormModal(false)}
                onAccept={async s3Keys => {
                    if (await preCreate()) {
                        const { listing: updatedListing, url } = await api
                            .post(
                                `listing/${listingType}/${listing.listingId}/rei/${formType.toLocaleLowerCase()}/create`,
                                {
                                    attachmentKeys: s3Keys,
                                },
                            )
                            .then(prop('data'))
                        setListing(updatedListing)
                        window.open(url, '_blank')

                        setListing(updatedListing)
                        setShowCreateFormModal(false)
                        showSnack('Form created', 'success')
                    } else {
                        showSnack('Error creating form', 'danger')
                        setLoading(false)
                    }
                }}
            />
        </>
    )
}

export default FormStepper
