import { prop } from 'ramda'
import z from 'zod'
import { ListingContractSchema } from './contract'
import { DocusignForm, DocusignOrManulForm } from './docusign'
import { AddressSchema, mobileOrPhoneType } from './generic'
import { CoreListingSchema, InternalListingSchema, ListingStatuses } from './listing'
import { ReiForm } from './rei'
import { Permission } from './roles'
import { FrontendUserSchema } from './userMembership'

export const ConjunctionalAgreementSchema = z
    .union([
        z.object({
            type: z.literal('external'),
            referrerName: z.string(),
        }),
        z.object({
            type: z.literal('internal'),
            agentId: z.string(),
        }),
    ])
    .and(
        z.union([
            z.object({
                agreementType: z.literal('fixed'),
                commissionAmount: z.number(),
            }),
            z.object({
                agreementType: z.literal('percentage'),
                commissionPercent: z.number(),
                comissionCap: z.number().optional(),
            }),
        ]),
    )

export const UpdateConjunctionalAgreementSchema = z.object({
    conjunctionalAgreements: z.array(ConjunctionalAgreementSchema),
})

export const TenantSchema = z.object({
    name: z.string(),
    email: z.string().email().optional(),
    mobileNumber: mobileOrPhoneType.optional(),
})

export const ListingTenantInfoSchema = z.object({
    tenancyInfo: z
        .object({
            tenantLeaseEnd: z.string().date().optional(),
            tenantLeaseStart: z.string().date().optional(),
            tenants: z.array(TenantSchema).default([]),
            managingAgent: z
                .union([
                    z.object({
                        type: z.literal('internal'),
                        userId: z.string(),
                    }),
                    z.object({
                        type: z.literal('external'),
                        name: z.string(),
                        email: z.string().email().optional(),
                        mobileNumber: mobileOrPhoneType.optional(),
                        agencyName: z.string().optional(),
                        agencyAddress: AddressSchema.optional(),
                    }),
                ])
                .optional(),
        })
        .and(
            z.union([
                z.object({
                    rentalAmount: z.number().optional(),
                    rentalFrequency: z.enum(['weekly', 'fortnightly', 'monthly']),
                }),
                z.object({}),
            ]),
        )
        .default({}),
})

const CoreSaleListingSchema = CoreListingSchema.and(
    z
        .object({
            commission: z.number().optional(), // Inclusive
            commissionIncEx: z.enum(['inc', 'ex']).optional().default('inc'),
            soldPrice: z.number().optional(),
            //occupantStatus: z.enum(['owner occupied', 'investment']).optional(),
            listingType: z.enum(['sale', 'auction']).optional(),
            auctionDate: z.string().optional(),
            sensitiveInfo: z.string().optional(),
        })
        .and(
            z.union([
                z.object({
                    occupantStatus: z.literal('owner occupied').optional(),
                }),
                z
                    .object({
                        occupantStatus: z.literal('investment'),
                    })
                    .and(ListingTenantInfoSchema),
            ]),
        ),
)

export const UpdateSaleListingSchema = CoreSaleListingSchema.and(
    z.object({
        listingStatus: z.enum(ListingStatuses).optional(),
        listingAddress: AddressSchema.optional(),
        commission: z.number().optional(), // Inclusive
        commissionIncEx: z.enum(['inc', 'ex']).optional().default('inc'),
        soldPrice: z.number().optional(),
        occupantStatus: z.enum(['owner occupied', 'investment']).optional(),
        listingType: z.enum(['sale', 'auction']).optional(),
        auctionDate: z.string().optional(),
        sensitiveInfo: z.string().optional(),
    }),
)

export const NewSaleListingSchema = CoreSaleListingSchema.and(
    z.object({
        listingStatus: z.enum(ListingStatuses).default('offline'),
        listingAddress: AddressSchema,
    }),
)

export type TenantSchema = z.infer<typeof TenantSchema>
export type ListingTenantInfoSchema = z.infer<typeof ListingTenantInfoSchema>
export type NewSaleListingSchema = z.infer<typeof NewSaleListingSchema>
export type ConjunctionalAgreementSchema = z.infer<typeof ConjunctionalAgreementSchema>
export type UpdateConjunctionalAgreementSchema = z.infer<typeof UpdateConjunctionalAgreementSchema>
export type UpdateSaleListingSchema = z.infer<typeof UpdateSaleListingSchema>
export type InternalSaleListingSchema = InternalListingSchema &
    NewSaleListingSchema & {
        listingId: string
        conjunctionalAgreements?: ConjunctionalAgreementSchema[]
        contract?: ListingContractSchema
        originalSettlementDate?: number
        agentAppointmentForm?: ReiForm
        contractForm?: DocusignOrManulForm
        disclosureStatementForm?: DocusignOrManulForm
        fundDisbursementForm?: DocusignForm
        tenantConsentOpenHomeForm?: ReiForm
        intentToSellForm?: ReiForm
        entryNoticeForm?: ReiForm
    }

export const listingEnabledForUser = (
    listing: InternalSaleListingSchema,
    usersData: FrontendUserSchema[] | undefined,
    user: FrontendUserSchema & { permissions: Permission[] },
) =>
    user.permissions.includes('listings.fullControl') ||
    (user.permissions.includes('listings.edit') &&
        (listing.agents.map(prop('userId')).includes(user.userId) ||
            listing.agents.map(prop('userId')).includes(user.salesAssistantLeadAgentId) ||
            (user.salesAssistantConjunctionalAgencyId != undefined &&
                usersData?.find(x => listing.agents.map(prop('userId')).includes(x.userId))
                    ?.conjunctionalAgencyId == user.salesAssistantConjunctionalAgencyId)))
