import { injectIf } from '@fastre/core/src/helperFunctions/object'
import {
    MarketingPackageSchema,
    UpdateMarketingPackageSchema,
} from '@fastre/core/src/schemas/marketingPackage'
import { zodResolver } from '@hookform/resolvers/zod'
import { AddRounded, Delete } from '@mui/icons-material'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    IconButton,
    Modal,
    ModalClose,
    ModalDialog,
    Sheet,
    Stack,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useMarketingItemsApi, useMarketingPackagesApi } from 'apiProviders'
import AgentInput, { MultipleAgentInput } from 'components/agentInput'
import ButtonSheet from 'components/buttonSheet'
import { SlotInput, SlotWrapper } from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { MobileFriendlyStack } from 'components/stack'
import { useMaybeState } from 'helperFunctions/react'
import { useOrgId } from 'helpers'
import { groupBy, prop, sortBy } from 'ramda'
import { useMemo, useState } from 'react'
import { Controller, SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { v4 as uuid } from 'uuid'

const EditPackageModal = ({ mpackage, close }: { mpackage: MarketingPackageSchema; close: () => void }) => {
    const isNew = mpackage.packageId == undefined
    const api = useApi()
    const showSnack = useShowSnack()
    const packagesApi = useMarketingPackagesApi()
    const itemsApi = useMarketingItemsApi()
    const orgId = useOrgId()

    const maybeGroupedSortedItems = useMemo(() => {
        return itemsApi.maybeData.map(items => {
            const grouped = groupBy(prop('itemCategory'), items)
            const a = Object.entries(grouped)
            return sortBy(([cat]) => cat, Object.entries(grouped))
        })
    }, [packagesApi.loading])

    const { register, control, handleSubmit, formState, getValues, setValue } =
        useForm<MarketingPackageSchema>({
            defaultValues: {
                ...injectIf(isNew, {
                    parentId: orgId!,
                    packageId: uuid(),
                }),
                ...mpackage,
            },
            resolver: zodResolver(UpdateMarketingPackageSchema),
        })

    const {
        fields: items,
        append: addItem,
        prepend,
        remove: removeItem,
        swap,
        move,
        insert: insertItem,
    } = useFieldArray({
        control,
        name: 'packageItems',
    })

    const itemsWatch = useWatch({ control, name: 'packageItems' })

    const [loading, setLoading] = useState(false)

    const onSubmit: SubmitHandler<MarketingPackageSchema> = async data => {
        setLoading(true)
        try {
            await api.post(`/marketingpackage/addupdate`, data).then(prop('data'))
            packagesApi.refresh()
            close()
            showSnack('Marketing package saved', 'success')
        } catch (e) {
            console.error(e)
            showSnack('Error saving marketing package', 'danger')
        } finally {
            setLoading(false)
        }
    }

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    return (
        <ModalDialog minWidth={800}>
            <DialogTitle>{isNew ? 'Create' : 'Edit'} Marketing Package</DialogTitle>
            <ModalClose />
            <DialogContent sx={{ overflow: 'auto' }}>
                <form
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Controller
                        name="packageName"
                        control={control}
                        render={field => (
                            <SlotInput
                                label="Package Name"
                                type="text"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="userIds"
                        control={control}
                        render={field => (
                            <Box sx={{ mt: 2 }}>
                                <SlotWrapper
                                    label="Available To"
                                    {...field}
                                >
                                    <MultipleAgentInput
                                        filter={prop('salesAgent')}
                                        placeholder="All Agents"
                                        {...field.field}
                                        value={field.field.value ?? []}
                                    />
                                </SlotWrapper>
                            </Box>
                        )}
                    />
                    <Typography
                        sx={{ mt: 2 }}
                        fontStyle="italic"
                    >
                        Add a new item or select from previously saved items:
                    </Typography>
                    <Stack
                        direction="row"
                        gap={1}
                    >
                        <Stack sx={{ flex: '1' }}>
                            <Sheet
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center',
                                    padding: 1, // Add padding to create spacing inside the box
                                    borderRadius: 1, // Rounded corners
                                    boxShadow: 1, // Optional shadow for effect
                                    width: 'auto', // Ensures the width adjusts based on content
                                    maxWidth: '100%', // Prevents it from exceeding the container's width
                                }}
                            >
                                <IconButton
                                    variant="outlined"
                                    onClick={() =>
                                        addItem({ itemCategory: 'Advertising', itemName: '', itemPrice: 0 })
                                    }
                                    size="sm"
                                >
                                    <AddRounded fontSize="small" />
                                </IconButton>
                                {'Add new item'}
                            </Sheet>
                            {maybeGroupedSortedItems
                                .map(groupedSortedItems =>
                                    groupedSortedItems.map(([category, categoryItems]) => (
                                        <>
                                            <Typography>{category}</Typography>
                                            {categoryItems
                                                .filter(item => !items.some(i => i.itemId === item.itemId))
                                                .map(item => (
                                                    <Sheet
                                                        key={item.itemId}
                                                        sx={{
                                                            display: 'flex',
                                                            gap: 2,
                                                            alignItems: 'center',
                                                            padding: 1, // Add padding to create spacing inside the box
                                                            borderRadius: 1, // Rounded corners
                                                            boxShadow: 1, // Optional shadow for effect
                                                            width: 'auto', // Ensures the width adjusts based on content
                                                            maxWidth: '100%', // Prevents it from exceeding the container's width
                                                        }}
                                                    >
                                                        <IconButton
                                                            variant="outlined"
                                                            onClick={() => addItem(item)}
                                                            size="sm"
                                                        >
                                                            <AddRounded fontSize="small" />
                                                        </IconButton>
                                                        {item.itemName} - ${item.itemPrice.toFixed(2)}
                                                    </Sheet>
                                                ))}
                                        </>
                                    )),
                                )
                                .orSome([])}
                        </Stack>
                    </Stack>

                    <Stack gap={1}>
                        {items.length > 0 && (
                            <Typography sx={{ fontWeight: 600, fontSize: 20, mb: 1, mt: 4 }}>
                                Items
                            </Typography>
                        )}
                        {items.map((item, itemIndex) => (
                            <MobileFriendlyStack
                                key={itemIndex}
                                direction="row"
                                gap={2}
                                alignItems="center"
                            >
                                {/* Display the itemIndex */}
                                <Controller
                                    name={`packageItems.${itemIndex}.itemName`}
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label={itemIndex == 0 ? 'Name' : ''} // Conditional label
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`packageItems.${itemIndex}.itemPrice`}
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label={itemIndex == 0 ? 'Price' : ''} // Conditional label
                                            type="number"
                                            startDecorator="$"
                                            {...field}
                                            onChange={e => {
                                                console.log('new val', e)
                                                field.field.onChange(e)
                                            }}
                                        />
                                    )}
                                />
                                <IconButton
                                    variant="outlined"
                                    onClick={() => removeItem(itemIndex)}
                                    //size="sm"
                                >
                                    <Delete /*fontSize="small"*/ />
                                </IconButton>
                            </MobileFriendlyStack>
                        ))}

                        {/* Row for Total Price - Only displayed when there are items */}
                        {items.length > 0 && (
                            <Box sx={{ mt: 3, display: 'flex', flexDirection: 'row' }}>
                                {/* Placeholder to match the "itemName" column */}
                                <Typography sx={{ width: 207 }}>
                                    <b>
                                        <i>Total Package Price:</i>
                                    </b>
                                </Typography>
                                <Typography sx={{ ml: 2 }}>
                                    <b>
                                        <i>
                                            $
                                            {itemsWatch
                                                .reduce((acc, item) => acc + item.itemPrice, 0)
                                                .toFixed(2)}
                                        </i>
                                    </b>
                                </Typography>
                            </Box>
                        )}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    //type="submit"
                    loading={loading}
                    onClick={handleSubmit(onSubmit)}
                >
                    Save
                </Button>
                <Button
                    variant="outlined"
                    onClick={close}
                >
                    Cancel
                </Button>
                {!isNew && (
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <Button
                            color="danger"
                            variant="outlined"
                            loading={loading}
                            onClick={async () => {
                                setLoading(true)
                                await api.post('/marketingpackage/delete', {
                                    parentId: orgId,
                                    packageId: mpackage.packageId,
                                })
                                await packagesApi.refresh()
                                close()
                            }}
                        >
                            Delete
                        </Button>
                    </Box>
                )}
            </DialogActions>
        </ModalDialog>
    )
}

const MarketingSettings = () => {
    const packagesApi = useMarketingPackagesApi()

    const [maybeEditPackage, setEditPackage] = useMaybeState<MarketingPackageSchema>()
    const [userFilter, setUserFilter] = useState<string | null>()

    return (
        <>
            <FormControl>
                <FormLabel>Filter by Agent</FormLabel>
                <AgentInput
                    value={userFilter}
                    onChange={setUserFilter}
                    filter={prop('salesAgent')}
                />
            </FormControl>
            <Box>
                <Stack
                    spacing={2}
                    sx={{ mt: 2 }}
                >
                    {packagesApi.maybeData
                        .map(packages =>
                            packages
                                .filter(
                                    p =>
                                        !userFilter ||
                                        p.userIds == undefined ||
                                        p.userIds.length == 0 ||
                                        p.userIds.includes(userFilter),
                                )
                                .map(p => (
                                    <ButtonSheet
                                        key={p.packageId}
                                        onClick={() => setEditPackage(p)}
                                    >
                                        {p.packageName}
                                    </ButtonSheet>
                                )),
                        )
                        .orSome([])}

                    <Box>
                        <Button
                            onClick={() =>
                                setEditPackage({
                                    packageName: '',
                                    packageItems: [],
                                })
                            }
                            sx={{ mt: 2 }}
                            variant="outlined"
                        >
                            New Package
                        </Button>
                    </Box>
                </Stack>
                <Modal
                    open={maybeEditPackage.isSome()}
                    onClose={dontCloseOnBackgroundClick(() => setEditPackage(undefined))}
                >
                    {maybeEditPackage
                        .map(p => (
                            <EditPackageModal
                                mpackage={p}
                                close={() => setEditPackage(undefined)}
                            />
                        ))
                        .orSome(<></>)}
                </Modal>
            </Box>
        </>
    )
}

export default MarketingSettings
