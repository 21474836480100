import { InternalLedgerSchema } from '@fastre/core/src/schemas/ledger'
import { Button } from '@mui/joy'
import { useBulkLedgerApi, useListingLedgerApi } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { useListingContext } from 'listings/listingProvider'
import { useState } from 'react'
import CoreLedgerTable from './coreLedgerTable'
import EditLedgerItemModal from './editLedgerItemModal'

export default function ListingLedger() {
    const { user: userData } = useUserData()
    const { listing } = useListingContext()
    const listingLedgerApi = useListingLedgerApi(listing.listingId)
    const rows = listingLedgerApi.data?.items
    const bulkLedgerApi = useBulkLedgerApi(false)

    const [editRow, setEditRow] = useState<InternalLedgerSchema>()

    if (!rows) return <Loading />

    return (
        <>
            <CoreLedgerTable
                rows={rows}
                ledgerApi={listingLedgerApi}
                showUser
            />
            {userData.permissions.includes('ledger.edit') && (
                <Button
                    variant="soft"
                    onClick={() =>
                        setEditRow({
                            userId: listing.agents.length == 1 ? listing.agents[0].userId : undefined,
                            listingId: listing.listingId,
                        } as any)
                    }
                >
                    Add Row
                </Button>
            )}
            <EditLedgerItemModal
                row={editRow}
                onClose={updated => {
                    setEditRow(undefined)
                    if (updated) {
                        listingLedgerApi.refresh()
                        bulkLedgerApi.refresh()
                    }
                }}
            />
        </>
    )
}
