import { InternalLedgerSchema } from '@fastre/core/src/schemas/ledger'
import { DeleteRounded, DoneRounded } from '@mui/icons-material'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Link,
    Modal,
    ModalDialog,
    Stack,
    Table,
    Textarea,
    Typography,
    useTheme,
} from '@mui/joy'
import { useApi } from 'api'
import {
    LedgerApiContext,
    useAgentStatementApi,
    useBulkLedgerApi,
    useConjunctionalAgenciesApi,
    useFindUserFromId,
    useUserLedgerApi,
} from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { format } from 'date-fns'
import OptionalTooltip from 'links/optionalTooltip'
import { prop, sum } from 'ramda'
import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import CoreLedgerTable from './coreLedgerTable'
import EditLedgerItemModal from './editLedgerItemModal'

interface LedgerProps {
    userId: string
    ledgerApi: Omit<LedgerApiContext, 'subscribe' | 'unsubscribe'>
}

export default ({ userId }: LedgerProps) => {
    const theme = useTheme()
    const api = useApi()
    const { user: userData } = useUserData()
    const conjunctionalAgenciesApi = useConjunctionalAgenciesApi()
    const findUserFromId = useFindUserFromId()
    const user = findUserFromId(userId).orUndefined()
    const showSnack = useShowSnack()
    const agentStatementApi = useAgentStatementApi(userId, false)
    const bulkLedgerApi = useBulkLedgerApi(false)
    const userLedgerApi = useUserLedgerApi(userId)

    const conjunctional = conjunctionalAgenciesApi.data?.find(
        x => x.conjunctionalAgencyId == user?.conjunctionalAgencyId,
    )

    const rows = userLedgerApi.data?.items
    const approved = userLedgerApi.data?.ledgerApproval

    const [editRow, setEditRow] = useState<InternalLedgerSchema>()

    const [approvalLoading, setApprovalLoading] = useState(false)
    const month = userLedgerApi.params.month as string | undefined

    const [createInvoiceLoading, setCreateInvoiceLoading] = useState(false)
    const [createAgentStatementLoading, setCreateAgentStatementLoading] = useState(false)

    const [comment, setComment] = useState<string>()
    const [commentLoading, setCommentLoading] = useState(false)

    if (!rows) return <Loading />

    const balance = sum(rows.map(row => (row.credit ?? 0) - (row.debit ?? 0)))

    return (
        <>
            <CoreLedgerTable
                rows={rows}
                ledgerApi={userLedgerApi}
            />
            <Stack
                direction="row"
                sx={{ mt: 2 }}
            >
                {userData.permissions.includes('ledger.edit') && (!approved || userData.superUser) && (
                    <Button
                        variant="soft"
                        onClick={() =>
                            setEditRow({
                                userId,
                                ledgerDate: month ?? format(new Date(), 'yyyy-MM-dd'),
                            } as any)
                        }
                    >
                        Add Row
                    </Button>
                )}
                {(userId == userData.userId || userData.permissions.includes('ledger.approve')) &&
                    approved == undefined && (
                        <OptionalTooltip
                            show={!userLedgerApi.data?.ledgerOpen}
                            title="Ledger has not yet been opened"
                        >
                            <Box sx={{ ml: 'auto' }}>
                                <Button
                                    loading={approvalLoading}
                                    onClick={async () => {
                                        setApprovalLoading(true)
                                        const ledgerApproval = await api
                                            .post('/ledger/approve', { userId, month: month! })
                                            .then(prop('data'))
                                        userLedgerApi.refresh()
                                        bulkLedgerApi.refresh()
                                        setApprovalLoading(false)
                                    }}
                                    disabled={!userLedgerApi.data?.ledgerOpen}
                                >
                                    Approve
                                </Button>
                            </Box>
                        </OptionalTooltip>
                    )}
                {approved && (
                    <Box
                        sx={{
                            display: 'flex',
                            ml: 'auto',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        {userData.permissions.includes('ledger.deleteApproval') && (
                            <Button
                                variant="outlined"
                                color="danger"
                                loading={approvalLoading}
                                startDecorator={<DeleteRounded />}
                                onClick={async () => {
                                    setApprovalLoading(true)
                                    await api.post('/ledger/unapprove', { userId, month })
                                    userLedgerApi.refresh()
                                    bulkLedgerApi.refresh()
                                    setApprovalLoading(false)
                                }}
                            >
                                Remove Approval
                            </Button>
                        )}
                        <DoneRounded
                            fontSize="large"
                            color="success"
                            sx={{
                                mr: 1,
                                width: '20px',
                                height: '20px',
                            }}
                        />
                        <Typography>Approved</Typography>
                    </Box>
                )}
            </Stack>
            {user !== undefined &&
                user.conjunctionalAgencyId == undefined &&
                approved &&
                !approvalLoading && (
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            justifyContent: 'right',
                        }}
                    >
                        {approved.agentStatementAdded ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    ml: 'auto',
                                    alignItems: 'center',
                                }}
                            >
                                <DoneRounded
                                    fontSize="large"
                                    color="success"
                                    sx={{
                                        mr: 1,
                                        width: '20px',
                                        height: '20px',
                                    }}
                                />
                                <Typography>Agent statement added</Typography>
                            </Box>
                        ) : (
                            <Button
                                loading={createAgentStatementLoading}
                                onClick={async () => {
                                    setCreateAgentStatementLoading(true)
                                    try {
                                        await api.post('/ledger/createagentstatement', {
                                            userId,
                                            month: format(month!, 'yyyy-MM-01'),
                                        })
                                        await Promise.all([
                                            userLedgerApi.refresh(),
                                            agentStatementApi.refresh(),
                                        ])
                                        bulkLedgerApi.refresh()
                                        showSnack('Agent statement added', 'success')
                                    } catch (e) {
                                        console.error(e)
                                        showSnack('Error adding agent statement', 'danger')
                                    } finally {
                                        setCreateAgentStatementLoading(false)
                                    }
                                }}
                            >
                                Add Agent Statement
                            </Button>
                        )}
                    </Box>
                )}
            <Box
                sx={{
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'right',
                }}
            >
                {userData.xeroConnected &&
                    userData.permissions.includes('ledger.approve') &&
                    !approvalLoading &&
                    user?.conjunctionalAgencyId != undefined &&
                    balance < 0 && (
                        <>
                            {approved?.xeroInvoice ? (
                                <>
                                    <Typography>
                                        Invoiced:{' '}
                                        <Link
                                            target="_blank"
                                            href={approved.xeroInvoice.url}
                                        >
                                            {approved.xeroInvoice.invoiceNumber}
                                        </Link>
                                    </Typography>
                                    {approved.xeroInvoiceStatus == 'PAID' && (
                                        <Typography
                                            sx={{
                                                color: theme.palette.success[500],
                                                ml: 1,
                                            }}
                                        >
                                            Paid
                                        </Typography>
                                    )}
                                </>
                            ) : (
                                <OptionalTooltip
                                    show={conjunctional?.xero == undefined}
                                    title="Must select Xero contact for agency first"
                                >
                                    <Box>
                                        {balance < 0 && (
                                            <Button
                                                loading={createInvoiceLoading}
                                                onClick={async () => {
                                                    setCreateInvoiceLoading(true)
                                                    await api.post('/xero/createledgerinvoice', {
                                                        userId,
                                                        month: format(month!, 'yyyy-MM-01'),
                                                    })
                                                    await userLedgerApi.refresh()
                                                    bulkLedgerApi.refresh()
                                                    setCreateInvoiceLoading(false)
                                                }}
                                                disabled={
                                                    approved == undefined || conjunctional?.xero == undefined
                                                }
                                            >
                                                Create Invoice
                                            </Button>
                                        )}
                                    </Box>
                                </OptionalTooltip>
                            )}
                        </>
                    )}
                {userData.permissions.includes('ledger.approve') &&
                    !approvalLoading &&
                    user?.conjunctionalAgencyId != undefined && (
                        <>
                            {balance > 0 &&
                                (approved?.creditMovedForwards ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            ml: 'auto',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <DoneRounded
                                            fontSize="large"
                                            color="success"
                                            sx={{
                                                mr: 1,
                                                width: '20px',
                                                height: '20px',
                                            }}
                                        />
                                        <Typography>Credit Carried Forwards</Typography>
                                    </Box>
                                ) : (
                                    <Button
                                        loading={createInvoiceLoading}
                                        onClick={async () => {
                                            setCreateInvoiceLoading(true)
                                            await api.post('/ledger/moveconjunctionalcreditforwards', {
                                                userId,
                                                month: format(month!, 'yyyy-MM-01'),
                                            })
                                            await userLedgerApi.refresh()
                                            bulkLedgerApi.refresh()
                                            setCreateInvoiceLoading(false)
                                        }}
                                        disabled={approved == undefined}
                                    >
                                        Carry Credit Forwards
                                    </Button>
                                ))}
                        </>
                    )}
            </Box>
            <Box sx={{ mt: 6 }}>
                <Typography
                    level="title-md"
                    sx={{ mb: 4 }}
                >
                    Comments
                </Typography>
                <Table>
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Date</th>
                            <th>Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userLedgerApi.data?.ledgerComments?.map(comment => (
                            <tr key={comment.id}>
                                <td>
                                    {findUserFromId(comment.userId)
                                        .map(user => `${user.firstName} ${user.lastName}`)
                                        .orSome('Loading')}
                                </td>
                                <td>{format(new Date(comment.timestamp), 'dd/MM/yyyy')}</td>
                                <td style={{ whiteSpace: 'pre' }}>{comment.comment}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {(userData.userId == userId || userData.permissions.includes('ledger.edit')) && (
                    <Button
                        variant="soft"
                        sx={{ mt: 4 }}
                        onClick={() => setComment('')}
                    >
                        Add Comment
                    </Button>
                )}
            </Box>

            <EditLedgerItemModal
                row={editRow}
                onClose={updated => {
                    setEditRow(undefined)
                    if (updated) {
                        userLedgerApi.refresh()
                        bulkLedgerApi.refresh()
                    }
                }}
            />
            <Modal
                open={comment != undefined}
                onClose={dontCloseOnBackgroundClick(() => setComment(undefined))}
            >
                <ModalDialog>
                    <DialogTitle>Add Comment</DialogTitle>
                    <DialogContent>
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Comment</FormLabel>
                                <Textarea
                                    value={comment}
                                    onChange={x => setComment(x.target.value)}
                                    minRows={3}
                                />
                            </FormControl>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            loading={commentLoading}
                            onClick={async () => {
                                setCommentLoading(true)
                                await api.post(`/ledger/addcomment`, {
                                    id: uuid(),
                                    userId,
                                    month: format(month!, 'yyyy-MM'),
                                    comment,
                                })
                                await userLedgerApi.refresh()
                                setCommentLoading(false)
                                setComment(undefined)
                            }}
                        >
                            Add Comment
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setComment(undefined)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </>
    )
}
