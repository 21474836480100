import { formatAddress } from '@fastre/core/src/schemas/generic'
import { TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { Box, Link, useTheme } from '@mui/joy'
import { useApi } from 'api'
import { useUsersApi } from 'apiProviders'
import { pick } from 'ramda'
import React, {
    ReactNode,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'
import { createPortal } from 'react-dom'
import { Editor, Range, Text, Transforms } from 'slate'
import { Editable, ReactEditor, Slate, useFocused, useSelected } from 'slate-react'
import { useDebounce } from 'use-debounce'

export const renderChat = (orgId: string) => node => {
    if (Text.isText(node)) {
        let string = node.text
        return string
    }

    switch (node.type) {
        case 'user':
            return (
                <Link>
                    @{node.character.firstName} {node.character.lastName}
                </Link>
            )
        case 'listing':
            return (
                <Link href={`/${orgId}/listings/sale/${node.character.listingId}`}>
                    #{formatAddress(node.character.listingAddress)}
                </Link>
            )
        case 'paragraph':
            return (
                <>
                    {node.children.map(renderChat(orgId))}
                    <br />
                </>
            )
        default:
            return node.children.map(renderChat(orgId))
    }
}

export const serializeChat = node => {
    if (Text.isText(node)) {
        let string = node.text
        return string
    }

    switch (node.type) {
        case 'user':
            return `${node.character.firstName} ${node.character.lastName}`
        case 'listing':
            return formatAddress(node.character.listingAddress)
        case 'paragraph':
            return node.children.map(serializeChat).join('\n')
        default:
            return node.children.map(serializeChat)
    }
}

export const Portal = ({ children }: { children?: ReactNode }) => {
    return typeof document === 'object' ? createPortal(children, document.body) : null
}

export type CustomText = {
    bold?: boolean
    italic?: boolean
    code?: boolean
    text: string
}

export type MentionElement = {
    type: 'user' | 'listing'
    character: string
    children: CustomText[]
}

const ChatInput = forwardRef(({ editor, initialContents, postMessage, ...textAreaProps }: any, ref) => {
    const api = useApi()
    const portalRef = useRef<HTMLDivElement | null>()
    const [target, setTarget] = useState<Range | undefined | null>()
    const [index, setIndex] = useState(0)
    const [search, setSearch] = useState('')
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])

    //    const [editor] = useState(() => withMentions(withReact(withHistory(createEditor()))))

    const usersApi = useUsersApi()
    const [searching, setSearching] = useState<'user' | 'listing'>('user')

    const [listingSearchText, setListingSearchText] = useState('')
    const [debouncedListingSearchText] = useDebounce(listingSearchText, 200)

    const chars = usersApi.maybeData
        .orSome([])
        .filter(
            c =>
                c.firstName.toLowerCase().startsWith(search.toLowerCase()) ||
                c.lastName.toLowerCase().includes(search.toLocaleLowerCase()),
        )
        .slice(0, 10)
        .map(({ userId, firstName, lastName }) => ({
            userId,
            firstName,
            lastName,
            name: `${firstName} ${lastName}`,
        }))

    const [searchListings, setSearchListings] = useState<
        Pick<TypesenseListingSchema, 'listingId' | 'listingAddress'>[] | undefined
    >()

    useEffect(() => {
        if (debouncedListingSearchText != '') {
            api.post(`/listings/{listingType}/search`, {
                search: debouncedListingSearchText,
                limit: 5,
            }).then(({ data }) =>
                setSearchListings(
                    data.hits.map((d: any) => pick(['listingId', 'orgId', 'listingAddress'], d.document)),
                ),
            )
        }
    }, [debouncedListingSearchText])

    const searchItems = searching == 'user' ? chars : (searchListings ?? [])

    const onKeyDown = useCallback(
        e => {
            if (target && searchItems.length > 0) {
                switch (e.key) {
                    case 'ArrowDown':
                        e.preventDefault()
                        const prevIndex = index >= searchItems.length - 1 ? 0 : index + 1
                        setIndex(prevIndex)
                        break
                    case 'ArrowUp':
                        e.preventDefault()
                        const nextIndex = index <= 0 ? searchItems.length - 1 : index - 1
                        setIndex(nextIndex)
                        break
                    case 'Tab':
                    case 'Enter':
                        e.preventDefault()
                        Transforms.select(editor, target)
                        insertMention(editor, searching, searchItems[index])
                        setTarget(null)
                        break
                    case 'Escape':
                        e.preventDefault()
                        setTarget(null)
                        break
                }
            }

            //console.log('e', e)
            if (e.key == 'Enter') {
                if (e.ctrlKey) {
                    e.preventDefault()
                    Transforms.insertText(editor, '\n')
                } else {
                    e.preventDefault()
                    postMessage()
                }
            }
        },
        [chars, searchListings, editor, index, target],
    )

    useEffect(() => {
        if (target && searchItems.length > 0) {
            const el = portalRef.current!
            const domRange = ReactEditor.toDOMRange(editor, target)
            const portalRect = el.getBoundingClientRect()
            const rect = domRange.getBoundingClientRect()
            el.style.top = `${rect.top + window.scrollY - portalRect.height - 10}px`
            el.style.left = `${rect.left + window.scrollX}px`
        }
    }, [chars.length, searchListings?.length, editor, index, search, target])

    useImperativeHandle(ref, () => ({
        getContents: () => editor.children,
    }))

    return (
        <Slate
            editor={editor}
            initialValue={[
                {
                    type: 'paragraph',
                    children: [
                        {
                            text: '',
                        },
                    ],
                } as any,
            ]}
            onChange={x => {
                const { selection } = editor

                if (selection && Range.isCollapsed(selection)) {
                    const [start] = Range.edges(selection)
                    const wordBefore = Editor.before(editor, start, {
                        unit: 'word',
                    })
                    const before = wordBefore && Editor.before(editor, wordBefore)
                    const beforeRange = before && Editor.range(editor, before, start)
                    const beforeText = beforeRange && Editor.string(editor, beforeRange)
                    const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
                    const beforeMatchHash = beforeText && beforeText.match(/^#(\w+)$/)

                    const after = Editor.after(editor, start)
                    const afterRange = Editor.range(editor, start, after)
                    const afterText = Editor.string(editor, afterRange)
                    const afterMatch = afterText.match(/^(\s|$)/)

                    if (beforeMatch && afterMatch) {
                        setSearching('user')
                        setTarget(beforeRange)
                        setSearch(beforeMatch[1])
                        setIndex(0)
                        return
                    }
                    if (beforeMatchHash && afterMatch) {
                        setSearching('listing')
                        setTarget(beforeRange)
                        setListingSearchText(beforeMatchHash[1])
                        setIndex(0)
                        return
                    }
                }

                setTarget(null)
            }}
        >
            <Editable
                ref={ref}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                onKeyDown={onKeyDown}
                //placeholder="Enter some text..."
                //disableDefaultStyles
                style={{
                    outline: 'none',
                }}
                {...textAreaProps}
            />
            {target && searchItems.length > 0 && (
                <Portal>
                    <Box
                        ref={portalRef as any}
                        sx={{
                            top: '-9999px',
                            left: '-9999px',
                            position: 'absolute',
                            zIndex: 9999,
                            padding: '3px',
                            backgroundColor: 'background.level3',
                            borderRadius: '4px',
                            boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                        }}
                        data-cy="mentions-portal"
                    >
                        {searchItems.map((char, i) =>
                            searching == 'user' ? (
                                <Box
                                    key={char.userId}
                                    onClick={() => {
                                        Transforms.select(editor, target)
                                        insertMention(editor, searching, char)
                                        setTarget(null)
                                    }}
                                    sx={{
                                        padding: '1px 3px',
                                        borderRadius: '3px',
                                        backgroundColor: i === index ? 'focusVisible' : 'transparent',
                                    }}
                                >
                                    {char.firstName} {char.lastName}
                                </Box>
                            ) : (
                                <Box
                                    key={char.listingId}
                                    onClick={() => {
                                        Transforms.select(editor, target)
                                        insertMention(editor, searching, char)
                                        setTarget(null)
                                    }}
                                    sx={{
                                        padding: '1px 3px',
                                        borderRadius: '3px',
                                        backgroundColor: i === index ? 'focusVisible' : 'transparent',
                                    }}
                                >
                                    {formatAddress(char.listingAddress)}
                                </Box>
                            ),
                        )}
                    </Box>
                </Portal>
            )}
        </Slate>
    )
})

const isMention = el => el.type == 'user' || el.type == 'listing'

export const withMentions = editor => {
    const { isInline, isVoid, markableVoid } = editor

    editor.isInline = element => {
        return isMention(element) ? true : isInline(element)
    }

    editor.isVoid = element => {
        return isMention(element) ? true : isVoid(element)
    }

    editor.markableVoid = element => {
        return isMention(element) || markableVoid(element)
    }

    return editor
}

const insertMention = (editor, type: 'user' | 'listing', character) => {
    const mention: MentionElement = {
        type,
        character,
        children: [{ text: '' }],
    }
    Transforms.insertNodes(editor, mention)
    Transforms.move(editor)
}

// Borrow Leaf renderer from the Rich Text example.
// In a real project you would get this via `withRichText(editor)` or similar.
export const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

const Element = props => {
    const { attributes, children, element } = props
    switch (element.type) {
        case 'user':
        case 'listing':
            return <Mention {...props} />
        default:
            return <p {...attributes}>{children}</p>
    }
}

export const Mention = ({ attributes, children, element }) => {
    const theme = useTheme()
    const selected = useSelected()
    const focused = useFocused()
    const style: React.CSSProperties = {
        padding: '3px 3px 2px',
        margin: '0 1px',
        verticalAlign: 'baseline',
        display: 'inline-block',
        borderRadius: '4px',
        //backgroundColor: '#eee',
        backgroundColor: 'background.level2',
        fontSize: '0.9em',
        boxShadow: selected && focused ? `0 0 0 2px ${theme.vars.palette.primary[500]}` : 'none',
    }
    // See if our empty text child has any styling marks applied and apply those
    if (element.children[0].bold) {
        style.fontWeight = 'bold'
    }
    if (element.children[0].italic) {
        style.fontStyle = 'italic'
    }

    console.log('element', element)

    if (element.type == 'user') {
        return (
            <Box
                component="span"
                {...attributes}
                contentEditable={false}
                //data-cy={`mention-${element.character.replace(' ', '-')}`}
                sx={style}
            >
                @{element.character.firstName} {element.character.lastName}
                {children}
            </Box>
        )
    } else {
        return (
            <Box
                component="span"
                {...attributes}
                contentEditable={false}
                //data-cy={`mention-${element.character.replace(' ', '-')}`}
                sx={style}
            >
                #{formatAddress(element.character.listingAddress)}
                {children}
            </Box>
        )
    }
}

export default ChatInput
