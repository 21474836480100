import { capitalize } from '@fastre/core/src/helperFunctions/string'
import { formatAddress } from '@fastre/core/src/schemas/generic'
import { ListingTenantInfoSchema } from '@fastre/core/src/schemas/saleListing'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded } from '@mui/icons-material'
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    IconButton,
    Option,
    Sheet,
    Stack,
    Switch,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import AgentInput from 'components/agentInput'
import { SlotInput, SlotWrapper } from 'components/input'
import LocationAutocomplete from 'components/LocationAutocomplete'
import Select from 'components/select'
import { useShowSnack } from 'components/snackbar'
import { MobileFriendlyStack } from 'components/stack'
import { prop } from 'ramda'
import { forwardRef, useImperativeHandle } from 'react'
import { Controller, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useEditMode } from './editMode'
import FormStepper from './formStepper'
import { useListingContext } from './listingProvider'

const TenantInfo = forwardRef(({}, ref) => {
    const api = useApi()
    const showSnack = useShowSnack()
    const { listing } = useListingContext()
    const { editMode, setEditMode } = useEditMode()

    const hookForm = useForm<ListingTenantInfoSchema>({
        defaultValues: listing.occupantStatus === 'investment' ? listing : undefined,
        resolver: zodResolver(ListingTenantInfoSchema),
    })

    const { control, handleSubmit, formState, getValues, setValue, trigger, reset, watch } = hookForm

    const {
        fields: tenants,
        append: appendTenant,
        remove: removeTenant,
    } = useFieldArray({
        control,
        name: 'tenancyInfo.tenants',
    })

    if (listing.occupantStatus !== 'investment') {
        return null
    }

    const disabled = false
    const formDisabled = false

    const managingAgentType = watch('tenancyInfo.managingAgent.type')

    const onSubmit: SubmitHandler<ListingTenantInfoSchema> = async data => {
        try {
            await api.post(`/listing/sale/${listing.listingId}/tenancyinfo`, data).then(prop('data'))
            showSnack('Tenancy info saved', 'success')
            return true
        } catch (e) {
            console.error(e)
            showSnack('Error saving tenancy info', 'danger')
            return false
        }
    }

    const handleSubmitPromise = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            handleSubmit(
                async data => {
                    try {
                        await onSubmit(data)
                        resolve(true)
                    } catch (error) {
                        resolve(false)
                    }
                },
                error => {
                    resolve(false)
                },
            )()
        })
    }

    // Expose the `saveForm` method to the parent via ref
    useImperativeHandle(ref, () => ({
        saveForm: handleSubmitPromise,
        resetForm: () => reset(listing.occupantStatus === 'investment' ? listing : undefined),
        disabled: formDisabled,
    }))

    return (
        <FormProvider {...hookForm}>
            <form noValidate>
                <Stack gap={2}>
                    <Typography level="h4">Managing Agent</Typography>
                    <Controller
                        control={control}
                        disabled={disabled}
                        name="tenancyInfo.managingAgent.type"
                        render={field =>
                            editMode ? (
                                <Switch
                                    {...field.field}
                                    checked={field.field.value == 'external'}
                                    onChange={e =>
                                        field.field.onChange(e.target.checked ? 'external' : 'internal')
                                    }
                                    startDecorator={({ checked }) => (
                                        <Typography sx={{ opacity: !checked ? 1 : 0.35 }}>
                                            Internal
                                        </Typography>
                                    )}
                                    endDecorator={({ checked }) => (
                                        <Typography sx={{ opacity: checked ? 1 : 0.35 }}>External</Typography>
                                    )}
                                    sx={{
                                        mr: 'auto',
                                    }}
                                />
                            ) : (
                                <></>
                            )
                        }
                    />
                    {managingAgentType === 'internal' && (
                        <Controller
                            name="tenancyInfo.managingAgent.userId"
                            control={control}
                            disabled={disabled}
                            render={field => (
                                <SlotWrapper
                                    {...field}
                                    label="Agent"
                                >
                                    <AgentInput {...field.field} />
                                </SlotWrapper>
                            )}
                        />
                    )}
                    {managingAgentType === 'external' && (
                        <Stack gap={2}>
                            <Controller
                                name="tenancyInfo.managingAgent.name"
                                control={control}
                                disabled={disabled}
                                render={field => (
                                    <SlotInput
                                        {...field}
                                        label="Name"
                                    />
                                )}
                            />
                            <MobileFriendlyStack>
                                <Controller
                                    name="tenancyInfo.managingAgent.email"
                                    control={control}
                                    disabled={disabled}
                                    render={field => (
                                        <SlotInput
                                            {...field}
                                            label="Email"
                                        />
                                    )}
                                />
                                <Controller
                                    name="tenancyInfo.managingAgent.mobileNumber"
                                    control={control}
                                    disabled={disabled}
                                    render={field => (
                                        <SlotInput
                                            {...field}
                                            label="Mobile Number"
                                        />
                                    )}
                                />
                            </MobileFriendlyStack>
                            <Controller
                                name="tenancyInfo.managingAgent.agencyName"
                                control={control}
                                disabled={disabled}
                                render={field => (
                                    <SlotInput
                                        {...field}
                                        label="Company Name"
                                    />
                                )}
                            />
                            <Controller
                                name="tenancyInfo.managingAgent.agencyAddress"
                                control={control}
                                disabled={disabled}
                                render={field =>
                                    disabled || !editMode ? (
                                        <FormControl>
                                            <FormLabel>Address</FormLabel>
                                            <Typography>
                                                {field.field.value
                                                    ? formatAddress(field.field.value, 'full')
                                                    : ''}
                                            </Typography>
                                        </FormControl>
                                    ) : (
                                        <LocationAutocomplete
                                            {...field}
                                            {...field.field}
                                            label="Company Address"
                                        />
                                    )
                                }
                            />
                        </Stack>
                    )}

                    <Typography
                        level="h4"
                        sx={{ mt: 2 }}
                    >
                        Tenancy
                    </Typography>
                    <MobileFriendlyStack gap={2}>
                        <Controller
                            name="tenancyInfo.tenantLeaseStart"
                            control={control}
                            render={field => (
                                <SlotInput
                                    {...field}
                                    label="Lease Start"
                                    type="date"
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="tenancyInfo.tenantLeaseEnd"
                            control={control}
                            render={field => (
                                <SlotInput
                                    {...field}
                                    label="Lease End"
                                    type="date"
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                    </MobileFriendlyStack>
                    <MobileFriendlyStack gap={2}>
                        <Controller
                            name="tenancyInfo.rentalAmount"
                            control={control}
                            render={field => (
                                <SlotInput
                                    {...field}
                                    label="Rental Amount"
                                    type="dollar"
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="tenancyInfo.rentalFrequency"
                            control={control}
                            render={field => (
                                <SlotWrapper
                                    {...field}
                                    label="Frequency"
                                >
                                    <Select
                                        {...field.field}
                                        value={field.field.value}
                                        onChange={(e, value) => field.field.onChange(value)}
                                        renderValue={x => (x ? capitalize(x as any) : '')}
                                    >
                                        {['weekly', 'fortnightly', 'monthly'].map(type => (
                                            <Option
                                                key={type}
                                                value={type}
                                            >
                                                {capitalize(type)}
                                            </Option>
                                        ))}
                                    </Select>
                                </SlotWrapper>
                            )}
                        />
                    </MobileFriendlyStack>
                </Stack>
                <Stack
                    gap={2}
                    sx={{ mt: 2 }}
                >
                    {tenants.map((tenant, i) => (
                        <Sheet
                            key={tenant.id}
                            variant="outlined"
                            sx={{
                                p: 2,
                                borderRadius: 'md',
                                mt: 2,
                            }}
                        >
                            <Stack
                                direction="row"
                                sx={{ mb: 2, justifyContent: 'space-between' }}
                            >
                                <Typography>Tenant {i + 1}</Typography>
                                {!disabled && editMode && (
                                    <Box>
                                        <IconButton
                                            variant="outlined"
                                            onClick={() => removeTenant(i)}
                                            size="sm"
                                        >
                                            <DeleteRounded fontSize="small" />
                                        </IconButton>
                                    </Box>
                                )}
                            </Stack>
                            <Stack gap={2}>
                                <Stack gap={2}>
                                    <Controller
                                        name={`tenancyInfo.tenants.${i}.name`}
                                        disabled={disabled}
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                label="Name"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`tenancyInfo.tenants.${i}.email`}
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                label="Email"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`tenancyInfo.tenants.${i}.mobileNumber`}
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                label="Mobile Number"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Stack>
                        </Sheet>
                    ))}
                </Stack>
                {!formDisabled && !disabled && editMode && (
                    <>
                        <Button
                            variant="soft"
                            sx={{ mt: 2 }}
                            onClick={() =>
                                appendTenant({
                                    name: '',
                                })
                            }
                        >
                            Add Tenant
                        </Button>

                        {/*<Box sx={{ mt: 6 }}>
                        <Button
                            type="submit"
                            loading={loading}
                            startDecorator={<SaveRounded />}
                        >
                            Save
                        </Button>
                    </Box>*/}
                    </>
                )}

                <Box sx={{ mt: 4 }}>
                    <FormStepper
                        title="Tenant Consent Open Home Form"
                        formType="tenantConsentOpenHomeForm"
                        createDisabled={formDisabled || tenants.length == 0}
                        validate={async () => {
                            var shouldFocus = true

                            /*const block = await Promise.all(
                                        requiredValues.map(async reqVal => {
                                            const error = getValues(reqVal) == undefined

                                            if (error) {
                                                setError(
                                                    reqVal,
                                                    {
                                                        type: 'required',
                                                        message: 'Required for Form 6',
                                                    },
                                                    {
                                                        shouldFocus,
                                                    },
                                                )

                                                shouldFocus = false
                                                console.log('error', reqVal)
                                            }
                                            return error
                                        }),
                                    )
                                    if (block.some(x => x == true)) {
                                        console.log('block time', Date.now() - start)
                                        return false
                                    }*/
                            return await trigger()
                        }}
                        preCreate={async () => {
                            if (formState.isDirty) {
                                return await handleSubmitPromise()
                                return true
                            } else {
                                return true
                            }
                        }}
                    />
                </Box>

                <Box sx={{ mt: 6 }}>
                    <FormStepper
                        title="Notice of Intention to Sell Form"
                        formType="intentToSellForm"
                        createDisabled={formDisabled || tenants.length == 0}
                        validate={async () => {
                            var shouldFocus = true

                            /*const block = await Promise.all(
                                        requiredValues.map(async reqVal => {
                                            const error = getValues(reqVal) == undefined

                                            if (error) {
                                                setError(
                                                    reqVal,
                                                    {
                                                        type: 'required',
                                                        message: 'Required for Form 6',
                                                    },
                                                    {
                                                        shouldFocus,
                                                    },
                                                )

                                                shouldFocus = false
                                                console.log('error', reqVal)
                                            }
                                            return error
                                        }),
                                    )
                                    if (block.some(x => x == true)) {
                                        console.log('block time', Date.now() - start)
                                        return false
                                    }*/
                            return await trigger()
                        }}
                        preCreate={async () => {
                            if (formState.isDirty) {
                                return await handleSubmitPromise()
                                return true
                            } else {
                                return true
                            }
                        }}
                    />
                </Box>

                <Box sx={{ mt: 6 }}>
                    <FormStepper
                        title="Entry Notice Form"
                        formType="entryNoticeForm"
                        createDisabled={formDisabled || tenants.length == 0}
                        validate={async () => {
                            var shouldFocus = true

                            /*const block = await Promise.all(
                            requiredValues.map(async reqVal => {
                                const error = getValues(reqVal) == undefined

                                if (error) {
                                    setError(
                                        reqVal,
                                        {
                                            type: 'required',
                                            message: 'Required for Form 6',
                                        },
                                        {
                                            shouldFocus,
                                        },
                                    )

                                    shouldFocus = false
                                    console.log('error', reqVal)
                                }
                                return error
                            }),
                        )
                        if (block.some(x => x == true)) {
                            console.log('block time', Date.now() - start)
                            return false
                        }*/
                            return await trigger()
                        }}
                        preCreate={async () => {
                            if (formState.isDirty) {
                                return await handleSubmitPromise()
                                return true
                            } else {
                                return true
                            }
                        }}
                    />
                </Box>
            </form>
        </FormProvider>
    )
})

export default TenantInfo
