import { getCommissionCalculatedPrice, UpdateMarketingSchema } from '@fastre/core/src/schemas/listing'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded, Visibility } from '@mui/icons-material'
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Sheet,
    Stack,
    styled,
    Typography,
    useTheme,
} from '@mui/joy'
import { useApi } from 'api'
import { useLinksApi, useListingType, useOrgDetailsApi } from 'apiProviders'
import { useUserData } from 'auth'
import axios from 'axios'
import Loading from 'components/Loading'
import FileUploadButton from 'components/fileUploadButton'
import Input, { SlotInput, SlotTextArea, SlotWrapper } from 'components/input'
import { useShowSnack } from 'components/snackbar'
import { MobileFriendlyStack } from 'components/stack'
import { useMaybeApiData } from 'helperFunctions/react'
import { LinkGridContainer } from 'links/components'
import LinkBox from 'links/linkBox'
import { pick, prop } from 'ramda'
import { useEffect, useImperativeHandle, useState } from 'react'
import { Controller, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import Resizer from 'react-image-file-resizer'
import { RenderListingValues } from './additionalValues'
import GptModal from './gptModal'
import { useListingContext } from './listingProvider'

const Item = styled(Sheet)(({ theme }) => ({
    backgroundImage:
        'url(https://i2.au.reastatic.net/800x600-format=webp/a96cc6648111903624658fabac4d06cad8298200727461ee7855e6af97382a20/image.jpg)',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
    ...theme.typography['body-sm'],
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: 4,
    height: 150,
    width: 200,
    backgroundSize: 'contain',
    color: theme.vars.palette.text.secondary,
}))

const resizeFile = (file): Promise<any> =>
    new Promise(resolve => {
        Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0, resolve, 'blob')
    })

const MarketingTab = ({ ref }) => {
    const api = useApi()
    const theme = useTheme()
    const { listing, setListing } = useListingContext()
    const listingType = useListingType()
    const showSnack = useShowSnack()
    const linksApi = useLinksApi()
    const orgDetailsApi = useOrgDetailsApi()
    const { user } = useUserData()

    const hookForm = useForm<UpdateMarketingSchema>({
        resolver: zodResolver(UpdateMarketingSchema),
        defaultValues: {
            publishTo: ['domain', 'realestate'],
            ...(listing as any),
        },
    })

    const formDisabled =
        !user.permissions.includes('listings.fullControl') &&
        !listing.agents.map(prop('userId')).includes(user.userId)

    const marketingLinks =
        linksApi.data?.flatMap(prop('links')).filter(link => link.showInTab == 'marketing') ?? []

    const { register, control, handleSubmit, formState, getValues, setValue, trigger, reset, watch } =
        hookForm

    const advertisedPrice = watch('advertisedPrice')
    const hiddenPrice = watch('hiddenPrice')
    const searchPriceType = watch('hiddenPrice.type')
    const listingSaleType = watch('listingType')
    const publishTo = watch('publishTo')
    const {
        fields: links,
        append: appendLink,
        remove: removeLink,
    } = useFieldArray({
        control,
        name: 'links',
    })

    const commissionCalculatedPrice = getCommissionCalculatedPrice({ advertisedPrice, hiddenPrice })

    useEffect(() => {
        reset(listing as any)
    }, [listing.listingId])

    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [maybeImages, refreshImages] = useMaybeApiData<{ name; fullImage; sampleImage }[]>(() =>
        api.get(`listing/${listingType}/${listing.listingId}/images`),
    )
    const [showGptModal, setShowGptModal] = useState(false)

    const address = `${listing.listingAddress?.streetNumber}-${listing.listingAddress?.streetName.replace(/ /g, '-')}-${listing.listingAddress?.suburb.replace(/ /g, '-')}-${listing.listingAddress?.state}-${listing.listingAddress?.postcode}`

    const onSubmit: SubmitHandler<UpdateMarketingSchema> = async data => {
        setSubmitLoading(true)
        try {
            const updatedListing = await api
                .post(`/listing/${listingType}/${listing.listingId}/updatemarketing`, data)
                .then(prop('data'))
            setListing(updatedListing)
            reset(updatedListing)
            showSnack('Contract saved', 'success')
        } catch (e) {
            console.error(e)
            showSnack('Error saving contract data', 'danger')
        } finally {
            setSubmitLoading(false)
        }
    }

    const handleSubmitPromise = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            handleSubmit(
                async data => {
                    try {
                        await onSubmit(data)
                        resolve(true)
                    } catch (error) {
                        resolve(false)
                    }
                },
                error => {
                    resolve(false)
                },
            )()
        })
    }

    useImperativeHandle(ref, () => ({
        saveForm: handleSubmitPromise,
        resetForm: () => reset(listing),
        disabled: formDisabled,
    }))

    return (
        <>
            <Box>
                <FormProvider {...hookForm}>
                    <form
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Stack gap={2}>
                            <Typography level="h4">Property Features</Typography>
                            <Controller
                                name="propertyInfo.beds"
                                control={control}
                                disabled={formDisabled}
                                render={field => (
                                    <SlotInput
                                        label="Beds"
                                        type="number"
                                        {...field}
                                        formControlProps={{
                                            sx: {
                                                flex: 1,
                                                flexShrink: 1,
                                            },
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="propertyInfo.baths"
                                control={control}
                                disabled={formDisabled}
                                render={field => (
                                    <SlotInput
                                        label="Baths"
                                        type="number"
                                        {...field}
                                        formControlProps={{
                                            sx: {
                                                flex: 1,
                                                flexShrink: 1,
                                            },
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="propertyInfo.cars"
                                control={control}
                                disabled={formDisabled}
                                render={field => (
                                    <SlotInput
                                        label="Parking"
                                        type="number"
                                        {...field}
                                        formControlProps={{
                                            sx: {
                                                flex: 1,
                                                flexShrink: 1,
                                            },
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="propertyInfo.landSize"
                                control={control}
                                disabled={formDisabled}
                                render={field => (
                                    <SlotInput
                                        label="Land Size"
                                        type="number"
                                        endDecorator="m²"
                                        {...field}
                                        autoComplete="off"
                                    />
                                )}
                            />
                            <Controller
                                name="propertyInfo.floorSize"
                                control={control}
                                disabled={formDisabled}
                                render={field => (
                                    <SlotInput
                                        label="Floor Size"
                                        type="number"
                                        endDecorator="m²"
                                        {...field}
                                    />
                                )}
                            />
                            <Box>
                                <Controller
                                    name="marketingDescription"
                                    control={control}
                                    disabled={formDisabled}
                                    render={field => (
                                        <SlotTextArea
                                            label="Description"
                                            minRows={5}
                                            {...field}
                                        />
                                    )}
                                />
                                <Typography
                                    level="body-xs"
                                    sx={{
                                        whiteSpace: 'pre',
                                        mt: 1,
                                    }}
                                >
                                    {orgDetailsApi.data?.marketingDisclosure}
                                </Typography>
                            </Box>
                            {!formDisabled && (
                                <Box
                                    sx={{
                                        mb: 2,
                                        display: 'flex',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={() => setShowGptModal(true)}
                                        disabled={listing.listingAddress?.streetName == undefined}
                                    >
                                        Chat GPT
                                    </Button>
                                </Box>
                            )}
                            <Typography level="h4">Links</Typography>
                            {links.map((link, i) => (
                                <Stack
                                    direction="row"
                                    gap={2}
                                    key={link.id}
                                >
                                    <Controller
                                        name={`links.${i}.url`}
                                        control={control}
                                        disabled={formDisabled}
                                        render={field => (
                                            <SlotInput
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                            />
                                        )}
                                    />
                                    <IconButton onClick={() => removeLink(i)}>
                                        <DeleteRounded />
                                    </IconButton>
                                </Stack>
                            ))}
                            {!formDisabled && (
                                <Box>
                                    <Button
                                        onClick={() => appendLink({ url: '' })}
                                        variant="outlined"
                                    >
                                        Add Link
                                    </Button>
                                </Box>
                            )}
                            <Controller
                                name="advertisedPrice"
                                disabled={formDisabled}
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label={listingSaleType == 'auction' ? 'Reserve' : 'Advertised Price'}
                                        {...field}
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                        //startDecorator="$"
                                        //type="dollar"
                                    />
                                )}
                            />
                            <Input
                                label="Estimated Commission Amount Inc"
                                value={
                                    listing.commission && commissionCalculatedPrice
                                        ? Math.round((commissionCalculatedPrice / 100) * listing.commission)
                                        : ''
                                }
                                readOnly
                                disabled
                                formControlProps={{
                                    sx: { flex: 1 },
                                }}
                                startDecorator="$"
                                type="dollar"
                            />
                            <MobileFriendlyStack>
                                <Controller
                                    name="hiddenPrice.type"
                                    control={control}
                                    disabled={formDisabled}
                                    render={field => (
                                        <SlotWrapper
                                            {...field}
                                            label="Search Price Type"
                                        >
                                            <RadioGroup orientation="horizontal">
                                                <Radio
                                                    value="fixed"
                                                    label="Fixed"
                                                    checked={field.field.value == 'fixed'}
                                                    disabled={formDisabled}
                                                />
                                                <Radio
                                                    value="range"
                                                    label="Range"
                                                    checked={field.field.value == 'range'}
                                                    disabled={formDisabled}
                                                />
                                            </RadioGroup>
                                        </SlotWrapper>
                                    )}
                                />
                            </MobileFriendlyStack>
                            {searchPriceType == 'fixed' && (
                                <MobileFriendlyStack>
                                    <Controller
                                        name="hiddenPrice.amount"
                                        control={control}
                                        disabled={formDisabled}
                                        render={field => (
                                            <SlotInput
                                                label="Search Price"
                                                type="dollar"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                                startDecorator="$"
                                            />
                                        )}
                                    />
                                </MobileFriendlyStack>
                            )}
                            <RenderListingValues tab="marketing" />
                            {searchPriceType == 'range' && (
                                <MobileFriendlyStack>
                                    <Controller
                                        name="hiddenPrice.min"
                                        disabled={formDisabled}
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                label="Min"
                                                type="dollar"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                                startDecorator="$"
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="hiddenPrice.max"
                                        control={control}
                                        disabled={formDisabled}
                                        render={field => (
                                            <SlotInput
                                                label="Max"
                                                type="dollar"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                                startDecorator="$"
                                            />
                                        )}
                                    />
                                </MobileFriendlyStack>
                            )}
                        </Stack>
                        <Box sx={{ flex: 1 }}>
                            <FormLabel sx={{ mb: 1.5 }}>Publish to</FormLabel>
                            <Stack
                                direction="row"
                                gap={2}
                            >
                                <Checkbox
                                    value="realestate"
                                    label="realestate.com.au"
                                    checked={publishTo && publishTo.includes('realestate')}
                                    disabled={formDisabled}
                                    {...register('publishTo')}
                                />
                                <Checkbox
                                    value="domain"
                                    label="domain.com.au"
                                    checked={publishTo && publishTo.includes('domain')}
                                    disabled={formDisabled}
                                    {...register('publishTo')}
                                />
                            </Stack>
                        </Box>
                    </form>
                </FormProvider>

                {marketingLinks.length > 0 && (
                    <>
                        <Divider sx={{ my: 4 }} />

                        <LinkGridContainer>
                            {marketingLinks.map(link => (
                                <Grid
                                    xs={1}
                                    key={link.linkName + link.linkUrl}
                                >
                                    <LinkBox
                                        link={link}
                                        onClick={() => {
                                            console.log('link', link)
                                            window.open(link.linkUrl, '_blank')
                                        }}
                                        sx={{
                                            ':hover': {
                                                background: theme.palette.background.level1,
                                            },
                                        }}
                                    />
                                </Grid>
                            ))}
                        </LinkGridContainer>
                    </>
                )}

                <Divider sx={{ my: 4 }} />

                <MobileFriendlyStack sx={{ mt: 3 }}>
                    <Button
                        style={{ backgroundColor: '#e5002b' }}
                        component="a"
                        startDecorator={<Visibility />}
                        href={`https://www.realestate.com.au/property/${address}`}
                        target="_blank"
                    >
                        realestate.com.au
                    </Button>
                    <Button
                        style={{ backgroundColor: '#10a800' }}
                        component="a"
                        startDecorator={<Visibility />}
                        href={`https://www.domain.com.au/property-profile/${address}`}
                        target="_blank"
                    >
                        domain.com.au
                    </Button>
                    <FileUploadButton
                        accept="image/*"
                        loading={loading}
                        onChange={async files => {
                            setLoading(true)
                            try {
                                const { presignedUrls } = await api
                                    .post(
                                        `listing/${listingType}/${listing.listingId}/images/getuploadurls`,
                                        {
                                            images: files.map(pick(['name', 'type'])),
                                        },
                                    )
                                    .then(prop('data'))
                                await Promise.all(
                                    files.map(async file => {
                                        const resized = await resizeFile(file)

                                        await axios.put(presignedUrls[file.name].fullImage, file, {
                                            headers: {
                                                'Content-Type': file.type,
                                            },
                                        })
                                        await axios.put(presignedUrls[file.name].sampleImage, resized, {
                                            headers: {
                                                'Content-Type': file.type,
                                            },
                                        })
                                    }),
                                )
                                showSnack('Image(s) uploaded', 'success')
                                await refreshImages()
                            } catch (e) {
                                console.log('e', e)
                                showSnack('Error uploading image(s)', 'danger')
                            } finally {
                                setLoading(false)
                            }
                        }}
                    >
                        Upload File
                    </FileUploadButton>
                </MobileFriendlyStack>

                {maybeImages
                    .map(images => (
                        <Grid
                            container
                            spacing={{ xs: 2, md: 4 }}
                            columns={{ xs: 4, sm: 4, md: 12 }}
                            sx={{ flexGrow: 1, mt: 3 }}
                        >
                            {images.map(image => (
                                <Grid
                                    xs={2}
                                    sm={4}
                                    md={3}
                                    key={image.name}
                                >
                                    <Box
                                        sx={{
                                            flex: 1,
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                maxWidth: '200px',
                                                maxHeight: '150px',
                                            }}
                                            onClick={() => window.open(image.fullImage, '_blank')}
                                        >
                                            <img
                                                src={image.sampleImage}
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                }}
                                            />
                                        </Box>
                                        {/*<div style={{flex:1 ,flexDirection: 'row', marginTop:8, alignSelf: 'center'}}>
                                        <Button value="plain"
                                            startDecorator={<Visibility style={{height:16, alignSelf: 'center'}}/>}
                                            style={{maxHeight: 10,marginRight: 10, fontSize: 8, fontWeight: 600, color: 'black', backgroundColor: 'white'}}
                                        >
                                            View
                                        </Button>
                                        <Button
                                            value="plain"
                                            style={{maxHeight: 10, marginRight: 10, fontSize: 8, fontWeight: 600, color: 'black', backgroundColor: 'white' }}
                                            startDecorator={<Delete style={{height:16}}/>}
                                        >
                                            Delete
                                        </Button>
                                    </div>*/}
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    ))
                    .orSome(<Loading />)}
            </Box>
            <GptModal
                open={showGptModal}
                close={() => setShowGptModal(false)}
                setValue={response => setValue('marketingDescription', response)}
                listingAddress={listing.listingAddress}
                propertyInfo={getValues('propertyInfo')}
            />
        </>
    )
}

export default MarketingTab
