import { Box, Sheet, Table, Typography, useTheme } from '@mui/joy'
import { useUsersApi } from 'apiProviders'
import { format, isSameMonth } from 'date-fns'
import { prop, sortBy, sum } from 'ramda'
import { ListingsSummary } from './types'

export default function MonthSummary({ listings, month }: { listings: ListingsSummary; month: Date }) {
    const theme = useTheme()
    const thisMonth = isSameMonth(month, new Date())
    const usersApi = useUsersApi()

    const newListings = usersApi.maybeData.orSome([]).map(({ userId }) => ({
        userId,
        newListings: listings.newListings.filter(
            listing =>
                listing.agents.map(prop('userId')).includes(userId) &&
                listing.authorityStartDate &&
                isSameMonth(listing.authorityStartDate, month),
        ).length,
    }))

    const underContract = usersApi.maybeData.orSome([]).map(({ userId }) => {
        const filteredListings = listings.underContract.filter(listing =>
            listing.agents.map(prop('userId')).includes(userId),
        )

        return {
            userId,
            uc: filteredListings.length,
            avgComm:
                filteredListings.length > 0
                    ? sum(filteredListings.map(l => l.commission ?? 0)) / filteredListings.length
                    : 0,
            mtdContracts: sum(filteredListings.map(x => x.contract?.salePrice ?? 0)),
        }
    })

    const data = (usersApi.data ?? [])
        .filter(user => user.salesAgent)
        .map(user => ({
            agent: `${user.firstName} ${user.lastName}`,
            uc: sum(underContract.filter(x => x.userId === user.userId).map(x => x.uc)),
            avgComm:
                underContract.filter(x => x.userId === user.userId).length > 0
                    ? (
                          sum(underContract.filter(x => x.userId === user.userId).map(x => x.avgComm)) /
                          underContract.filter(x => x.userId === user.userId).length
                      ).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                      }) + '%'
                    : '-',
            mtdContracts: sum(underContract.filter(x => x.userId === user.userId).map(x => x.mtdContracts)),
            newListings: sum(newListings.filter(x => x.userId === user.userId).map(x => x.newListings)),
        }))

    return (
        <Box>
            <Typography
                level="h2"
                sx={{ mb: 2 }}
            >
                {format(month, 'LLLL')}
            </Typography>
            <Sheet
                variant="outlined"
                sx={{
                    width: '100%',
                    borderRadius: 'sm',
                }}
            >
                <Table
                    sx={{
                        '& th': {
                            height: '65px',
                            backgroundColor: thisMonth ? '#8B0000' : '#002147',
                            color: 'white',
                        },
                        '& tr': {
                            '&:nth-of-type(odd)': {
                                backgroundColor: theme.palette.background.level1,
                            },
                        },
                    }}
                >
                    <thead>
                        <tr>
                            <th>Agent</th>
                            <th
                                style={{ textAlign: 'right' }}
                                align="right"
                            >
                                U/C
                            </th>
                            <th
                                style={{ textAlign: 'right' }}
                                align="right"
                            >
                                Avg Comm
                            </th>
                            <th
                                style={{ textAlign: 'right' }}
                                align="right"
                            >
                                {thisMonth && 'MTD '}Contracts $
                            </th>
                            <th
                                style={{ textAlign: 'right' }}
                                align="right"
                            >
                                New Listings
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortBy(x => -x.mtdContracts, data).map((row, index) => (
                            <tr key={index}>
                                <td>{row.agent}</td>
                                <td align="right">{row.uc}</td>
                                <td align="right">{row.avgComm}</td>
                                <td align="right">
                                    $
                                    {row.mtdContracts.toLocaleString('en-US', {
                                        maximumFractionDigits: 0,
                                    })}
                                </td>
                                <td align="right">{row.newListings}</td>
                            </tr>
                        ))}
                        <tr>
                            <td>
                                <strong>Total</strong>
                            </td>
                            <td align="right">
                                <strong>{sum(data.map(x => x.uc))}</strong>
                            </td>
                            <td align="right"></td>
                            <td align="right">
                                <strong>
                                    $
                                    {sum(data.map(x => x.mtdContracts)).toLocaleString('en-US', {
                                        maximumFractionDigits: 0,
                                    })}
                                </strong>
                            </td>
                            <td align="right">
                                <strong>{sum(data.map(x => x.newListings))}</strong>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Sheet>
        </Box>
    )
}
