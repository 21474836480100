import { addDays } from 'date-fns'
import { pick } from 'ramda'
import z from 'zod'
import { DocusignOrManulForm } from '../docusign'
import { ListingStatuses } from '../listing'
import { ReiForm } from '../rei'
import { InternalSaleListingSchema } from '../saleListing'

const getFormStatus = (form?: ReiForm | DocusignOrManulForm) => form?.formStatus //? (form.formStatus == 'manual upload' ? 'completed' : form.formStatus) : undefined

export const transformListingForAutomation = (listing: InternalSaleListingSchema) => ({
    ...pick(
        [
            'advertisedPrice',
            'hiddenPrice',
            'listingStatus',
            'marketingDescription',
            'commission',
            'listingAddress',
        ],
        listing,
    ),
    authorityStartDate: listing.authorityStartDate ? new Date(listing.authorityStartDate) : undefined,
    authorityEndDate: listing.authorityStartDate
        ? addDays(new Date(listing.authorityStartDate), listing.authorityPeriod!)
        : undefined,
    agentAppointmentFormStatus: getFormStatus(listing.agentAppointmentForm),
    contractFormStatus: getFormStatus(listing.contractForm),
})

export type AutomationSaleListing = ReturnType<typeof transformListingForAutomation>
type AutomationSaleListingKeys = keyof AutomationSaleListing

export type AutomationFieldType = 'string' | 'number' | 'date' | 'object' | 'enum'

export const validAutomationListingFields: Array<[AutomationSaleListingKeys, string, AutomationFieldType]> = [
    ['advertisedPrice', 'Advertised Price', 'number'],
    ['hiddenPrice', 'Search Price', 'number'],
    ['listingAddress', 'Address', 'object'],
    ['commission', 'Commission', 'number'],
    ['authorityStartDate', 'Authority Start Date', 'date'],
    ['authorityEndDate', 'Authority End Date', 'date'],
    ['agentAppointmentFormStatus', 'Agent Appointment Form Status', 'enum'],
    ['contractFormStatus', 'Contract Form Status', 'enum'],
    ['marketingDescription', 'Marketing Description', 'object'],
    ['listingStatus', 'Listing Status', 'enum'],

    // calculated fields
    ['authorityEndDate', 'Authority End Date', 'date'],
]

export const getFieldDescription = (trigger?: AutomationSaleListingKeys) =>
    validAutomationListingFields.find(([field]) => field === trigger)?.[1] ?? ''

export const getEnumsFromListingField = (field: AutomationSaleListingKeys) => {
    switch (field) {
        case 'listingStatus':
            return ListingStatuses
        case 'agentAppointmentFormStatus':
        case 'contractFormStatus':
            return ['draft', 'finalised', 'sent', 'completed', 'manual upload']
        default:
            return []
    }
}

export const AutomationTriggerUpdatedSchema = z.object({
    triggerType: z.literal('updated'),
})

export const AutomationTrigger_EqSchema = z.object({
    triggerType: z.enum(['eq', 'neq', 'set', 'gt', 'lt', 'gte', 'lte']),
    triggerFieldValue: z.array(z.string().or(z.number())),
})

/*export const AutomationTrigger_ContainsSchema = z.object({
    triggerType: z.literal('contains'),
    triggerFieldValue: z.array(z.string().or(z.number())),
})*/

export const AutomationTrigger_UpcomingDateSchema = z.object({
    triggerType: z.literal('upcomingDate'),
    daysBefore: z.number(), // negative number signifies days after
})

export const AutomationTriggerSchema = z
    .object({
        triggerFieldName: z.string(), // can be nested with '.' separator
    })
    .and(
        z.union([
            AutomationTriggerUpdatedSchema,
            AutomationTrigger_EqSchema,
            //AutomationTrigger_ContainsSchema,
            AutomationTrigger_UpcomingDateSchema,
        ]),
    )

export const AutomationTriggerEquationSchema = z.object({
    trigger: AutomationTriggerSchema,
    condition: z.enum(['and', 'or']),
})

export type AutomationTriggerUpdatedSchema = z.infer<typeof AutomationTriggerUpdatedSchema>
export type AutomationTrigger_EqSchema = z.infer<typeof AutomationTrigger_EqSchema>
//export type AutomationTrigger_ContainsSchema = z.infer<typeof AutomationTrigger_ContainsSchema>
export type AutomationTriggerSchema = z.infer<typeof AutomationTriggerSchema>
export type AutomationTriggerEquationSchema = z.infer<typeof AutomationTriggerEquationSchema>

export const getTriggerDescription = (triggerType: AutomationTriggerSchema['triggerType']) => {
    switch (triggerType) {
        case 'updated':
            return 'Updated'
        case 'eq':
            return 'Equals'
        case 'neq':
            return 'Does not equal'
        case 'gt':
            return 'Greater than'
        case 'lt':
            return 'Less than'
        case 'gte':
            return 'Greater than or equal to'
        case 'lte':
            return 'Less than or equal to'
        case 'set':
            return 'Is Set'
        /*case 'contains':
            return 'Contains'*/
        case 'upcomingDate':
            return 'Upcoming date'
    }
}

export const getValidTriggersForFieldType = (
    fieldType: AutomationFieldType,
): AutomationTriggerSchema['triggerType'][] => {
    const triggersFromType = () => {
        switch (fieldType) {
            case 'string':
                return ['eq', 'neq']
            case 'number':
                return ['eq', 'neq', 'gt', 'lt', 'gte', 'lte']
            case 'date':
                return ['upcomingDate']
            case 'object':
                return ['set']
            case 'enum':
                return ['eq', 'neq']
        }
    }

    return [...(triggersFromType() as AutomationTriggerSchema['triggerType'][]), 'updated']
}
