import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Sheet from '@mui/joy/Sheet'
import Typography from '@mui/joy/Typography'
import {
    AgentStatementProvider,
    AutomationsProvider,
    BulkLedgerProvider,
    CalendarEventsProvider,
    ChatConfigProvider,
    ChatGroupProvider,
    ChatProvider,
    ConjunctionalAgenciesApiDataProvider,
    EmailTemplatesProvider,
    GroupsProvider,
    LedgerMonthProvider,
    LinksApiProvider,
    ListingConfigProvider,
    MarketingItemsProvider,
    MarketingPackagesProvider,
    NotificationsProvider,
    OrgDetailsProvider,
    OrgIntegrationsProvider,
    PayrollProvider,
    RolesProvider,
    SaleListingsProvider,
    SavedFiltersProvider,
    SharedTodosProvider,
    SmsTemplatesProvider,
    SpecialConditionSnippetsProvider,
    TodoProvider,
    UserLedgerProvider,
    UsersProvider,
    XeroAccountsProvider,
} from 'apiProviders'
import { GeneralWebsocketProvider } from 'apiProviders/websocketProvider'
import { AuthWrapper, logout, useOrgListData, UserDataContext } from 'auth'
import Chat from 'chat'
import CloseDocusignIframe from 'closeDocusignIframe'
import Header from 'components/header'
import Loading from 'components/Loading'
import Sidebar from 'components/sidebar'
import Dashboard from 'dashboard'
import { useOrgId } from 'helpers'
import LedgerManager from 'ledgerManager'
import Links from 'links'
import Listings from 'listings'
import Map from 'listings/map'
import NotificationsPage from 'notificationsPage'
import { omit } from 'ramda'
import { lazy, Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'
import RolesPage from 'roles'
import SalesBoard from 'salesBoard'
import {
    DocusignConnectionResponse,
    LoggedInDocusignConnectionResponse,
} from 'settings/docusignConnectionResponse'
import SettingsPage from 'settings/settingsPage'
import { LoggedInXeroConnectionResponse, XeroConnectionResponse } from 'settings/xeroConnectionResponse'
import Todo from 'todo'
import Users, { AddUser } from 'users'
import ConjunctionalAgencies from 'users/conjunctionalAgencies'
import { ReiSessionProvider } from 'users/reiConnection'
import Login from './newLogin'
import ReiConnectionResponse, { LoggedInReiConnectionResponse } from './settings/reiConnectionResponse'
import TsChat from './tsChat'
import UserSettings from './userSettings'

const CalendarPage = lazy(() => import('./calendar'))

const OrgList = () => {
    const { orgListUserData } = useOrgListData()
    const navigate = useNavigate()

    return (
        <Box sx={{ p: 8 }}>
            <Typography
                fontSize={18}
                mb={4}
            >
                Orgs
            </Typography>
            {Object.entries(orgListUserData.orgData).map(([orgId, orgData]) => (
                <Sheet
                    variant="outlined"
                    sx={{
                        p: 2,
                        ':hover': {
                            cursor: 'pointer',
                            backgroundColor: 'rgba(0,0,0,0.1)',
                        },
                    }}
                    onClick={() => navigate(`/${orgId}`)}
                >
                    {orgData.orgName}
                </Sheet>
            ))}
            <Button onClick={logout}>Logout</Button>
        </Box>
    )
}

const OrgRoutes = () => (
    <Routes>
        <Route
            path="/rei/auth"
            element={<LoggedInReiConnectionResponse />}
        />
        <Route
            path="/docusign/auth"
            element={<LoggedInDocusignConnectionResponse />}
        />
        <Route
            path="/xero/auth"
            element={<LoggedInXeroConnectionResponse />}
        />
        <Route
            path="/adduser"
            element={<AddUser />}
        />
        <Route
            path="/users/*"
            element={<Users />}
        />
        <Route
            path="/roles"
            element={<RolesPage />}
        />
        <Route
            path="/conjunctionalagencies/*"
            element={<ConjunctionalAgencies />}
        />
        <Route
            path="/listings/map/:listingType"
            element={<Map />}
        />
        <Route
            path="/listings/:listingType/*"
            element={<Listings />}
        />
        <Route
            path="/links/*"
            element={<Links />}
        />
        <Route
            path="/ledgermanager/*"
            element={<LedgerManager />}
        />
        <Route
            path="/salesboard/*"
            element={<SalesBoard />}
        />
        <Route
            path="/tschat/*"
            element={<TsChat />}
        />
        <Route
            path="/calendar/*"
            element={
                <Suspense fallback={<Loading />}>
                    <CalendarPage />
                </Suspense>
            }
        />
        <Route
            path="/settings/*"
            element={<SettingsPage />}
        />
        <Route
            path="/usersettings/*"
            element={<UserSettings />}
        />
        <Route
            path="/dashboard/*"
            element={<Dashboard />}
        />
        <Route
            path="/chat/:chatGroupId"
            element={<Chat />}
        />
        <Route
            path="/todo"
            element={<Todo />}
        />
        <Route
            path="/notifications"
            element={<NotificationsPage />}
        />
        <Route
            index
            element={
                <Navigate
                    to="dashboard"
                    replace
                />
            }
        />
    </Routes>
)

export const WithProviders = ({ providers, children }) => {
    const Provider = providers[0]

    return (
        <Provider>
            {providers.length > 1 ? (
                <WithProviders providers={providers.slice(1, providers.length)}>{children}</WithProviders>
            ) : (
                children
            )}
        </Provider>
    )
}

const WithOrg = () => {
    const { orgListUserData, refreshOrgListUserData } = useOrgListData()
    const orgId = useOrgId()

    if (!orgListUserData.orgData[orgId!]) {
        return <Typography fontSize={18}>Org not found</Typography>
    }

    const orgData = orgListUserData.orgData[orgId!]

    return (
        <UserDataContext.Provider
            value={{
                user: {
                    //orgId: orgId!,
                    ...omit(['orgData'], orgListUserData),
                    ...orgData,
                    salesAssistant:
                        orgData.salesAssistantLeadAgentId != undefined ||
                        orgData.salesAssistantConjunctionalAgencyId != undefined,
                },
                refreshUser: refreshOrgListUserData,
            }}
        >
            <GeneralWebsocketProvider>
                <WithProviders
                    providers={[
                        LedgerMonthProvider,
                        UsersProvider,
                        SaleListingsProvider,
                        //BdmListingsProvider,
                        GroupsProvider,
                        MarketingItemsProvider,
                        MarketingPackagesProvider,
                        SmsTemplatesProvider,
                        ConjunctionalAgenciesApiDataProvider,
                        LinksApiProvider,
                        ListingConfigProvider,
                        ReiSessionProvider,
                        AutomationsProvider,
                        NotificationsProvider,
                        ChatGroupProvider,
                        ChatConfigProvider,
                        ChatProvider,
                        SavedFiltersProvider,
                        TodoProvider,
                        SharedTodosProvider,
                        OrgDetailsProvider,
                        CalendarEventsProvider,
                        OrgIntegrationsProvider,
                        RolesProvider,
                        BulkLedgerProvider,
                        AgentStatementProvider,
                        UserLedgerProvider,
                        XeroAccountsProvider,
                        PayrollProvider,
                        EmailTemplatesProvider,
                        SpecialConditionSnippetsProvider,
                    ]}
                >
                    <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                        <Header />
                        <Sidebar />
                        <Box
                            component="main"
                            className="MainContent"
                            sx={{
                                pt: {
                                    xs: 'calc(12px + var(--Header-height))',
                                    md: 3,
                                },
                                pb: {
                                    xs: 4,
                                    sm: 4,
                                    md: 6,
                                },
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: 0,
                                height: '100dvh',
                                gap: 1,
                                overflow: 'auto',
                            }}
                        >
                            <OrgRoutes />
                        </Box>
                    </Box>
                </WithProviders>
            </GeneralWebsocketProvider>
        </UserDataContext.Provider>
    )
}

const LoggedInRoutes = () => {
    const { orgListUserData } = useOrgListData()
    const orgs = Object.entries(orgListUserData.orgData)

    return (
        <Routes>
            <Route
                path="/:orgId/*"
                element={<WithOrg />}
            />
            <Route
                path="/rei/auth"
                element={<ReiConnectionResponse />}
            />
            <Route
                index
                element={
                    Object.entries(orgListUserData.orgData).length == 1 ? (
                        <Navigate to={orgs[0][0]} />
                    ) : (
                        <OrgList />
                    )
                }
            />
        </Routes>
    )
}

const WithAuthWrapper = () => {
    return (
        <AuthWrapper>
            <Routes>
                <Route
                    path="auth/*"
                    element={<Login />}
                />
                <Route
                    path="/*"
                    element={<LoggedInRoutes />}
                />
            </Routes>
        </AuthWrapper>
    )
}

export default () => {
    const [searchParams] = useSearchParams()
    const expoPushToken = searchParams.get('expoPushToken')

    useEffect(() => {
        if (expoPushToken) {
            localStorage.setItem('expoPushToken', expoPushToken)
        }
    }, [expoPushToken])

    return (
        <Routes>
            <Route
                path="/rei/auth"
                element={<ReiConnectionResponse />}
            />
            <Route
                path="/docusign/auth"
                element={<DocusignConnectionResponse />}
            />
            <Route
                path="/xero/auth"
                element={<XeroConnectionResponse />}
            />
            <Route
                path="/docusign/close"
                element={<CloseDocusignIframe />}
            />
            <Route
                path="/*"
                element={<WithAuthWrapper />}
            />
        </Routes>
    )
}
