import { Active, DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { ownsListing } from '@fastre/core/src/schemas/listing'
import { ContentCopy, EmailRounded, InsertDriveFileRounded } from '@mui/icons-material'
import {
    Box,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    Modal,
    ModalClose,
    ModalDialog,
    Sheet,
    Typography,
    useTheme,
} from '@mui/joy'
import { useApi } from 'api'
import { ListingFilesApiData, useListingFilesApi, useListingType, useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import axios from 'axios'
import Loading from 'components/Loading'
import FileUploadButton from 'components/fileUploadButton'
import PdfModal from 'components/pdfModal'
import { useShowSnack } from 'components/snackbar'
import copy from 'copy-to-clipboard'
import { useMaybeState } from 'helperFunctions/react'
import { evolve, pick, prop, T } from 'ramda'
import { useMemo, useState } from 'react'
import { useListingContext } from '../listingProvider'
import { MoveFilesContext, UploadFilesContext } from './context'
import EmailModal from './emailModal'
import FolderRender from './folderRender'

const { VITE_APP_STAGE } = import.meta.env

interface FilesListProps {
    setFile: (file: { name: string; url: string }) => void
    children: any
    formDisabled: boolean
}

const FilesList = ({ setFile, children, formDisabled }: FilesListProps) => (
    <List>
        <FolderRender
            file={{
                Key: '',
                name: '',
                Size: 0,
                loading: false,
                isFolder: true,
                isEmail: false,
            }}
            setFile={setFile}
            index={0}
            parentDragging={false}
            formDisabled={formDisabled}
        />
        {children}
    </List>
)

const Files = () => {
    const api = useApi()
    const listingType = useListingType()
    const { listing } = useListingContext()
    const showSnack = useShowSnack()
    const theme = useTheme()
    const filesApi = useListingFilesApi()
    const usersApi = useUsersApi()
    const { user } = useUserData()

    const [loading, setLoading] = useState(false)
    const [maybeFile, setFile] = useMaybeState<{ name: string; url: string }>()
    //const [showAddFolder, setShowAddFolder] = useState(false)

    const [active, setActive] = useState<Active | null>(null)
    const activeItem = useMemo(
        () => (active ? filesApi.maybeData.orSome([]).find(file => file.Key === active?.id) : undefined),
        [active],
    )

    const owns = ownsListing(listing)

    const formEnabled =
        user.permissions.includes('listings.fullControl') ||
        (user.permissions.includes('listings.edit') &&
            (owns(user.userId) ||
                (user.salesAssistantLeadAgentId && owns(user.salesAssistantLeadAgentId)) ||
                (user.salesAssistantConjunctionalAgencyId &&
                    usersApi.data?.find(x => owns(x.userId))?.conjunctionalAgencyId ==
                        user.salesAssistantConjunctionalAgencyId)))

    const formDisabled = !formEnabled

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        /*useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),*/
    )

    const uploadFiles = async (files: File[], _folder?: string[]) => {
        const folder = _folder?.filter(x => x != '') ?? []

        console.log('uploading files', files, folder)

        setLoading(true)

        const backupFiles = filesApi.maybeData.orSome([])

        const fullFilenames = files.map(file => ({
            ...file,
            name: (folder.length > 0 ? folder.join('/') + '/' : '') + file.name,
        }))

        const newFiles = [
            ...filesApi.maybeData.orSome([]),
            ...fullFilenames.map(file => ({
                Key: file.name,
                Size: file.size ?? 0,
                loading: true,
            })),
        ]

        filesApi.setVals(newFiles)

        try {
            const { presignedUrls } = await api
                .post(`listing/${listingType}/${listing.listingId}/file/getuploadurls`, {
                    folder: folder ?? [],
                    files: files.map(pick(['name', 'type'])),
                })
                .then(prop('data'))
            await Promise.all(
                files.map(async file => {
                    console.log('FILE TYPE', file.type)

                    return axios.put(presignedUrls[file.name], file, {
                        headers: {
                            'Content-Type': file.type,
                        },
                    })
                }),
            )
            showSnack('File(s) uploaded', 'success')
            filesApi.setVals(
                newFiles.map(file => ({
                    ...file,
                    loading: fullFilenames.some(f => f.name === file.Key) ? false : file.loading,
                })),
            )
            //await filesApi.refresh()
        } catch (e) {
            showSnack('Error uploading file(s)', 'danger')
            filesApi.setVals(backupFiles)
        } finally {
            setLoading(false)
        }
    }

    console.log('data', filesApi.data!)

    const moveFiles = async (source: ListingFilesApiData, destinationFolder: string) => {
        if (destinationFolder.endsWith('/')) {
            destinationFolder = destinationFolder.slice(0, -1)
        }

        const files = filesApi.data!

        const sourceParentFolder = source.name.split('/').slice(0, -1).join('/')

        console.log('source name', source.name)
        const sources = source!.isFolder
            ? files
                  .filter(file => {
                      const match = file.Key.startsWith(source.name)
                      console.log('checking', file.Key, match)
                      return match
                  })
                  .map(prop('Key'))
            : [source.Key]

        console.log({
            sourceParentFolder,
            destinationFolder,
            sources,
        })

        const backupFiles = filesApi.maybeData.orSome([])

        const moveRenamerFunction = key =>
            key.replace(
                sourceParentFolder ? sourceParentFolder + '/' : sourceParentFolder,
                destinationFolder == '' ? '' : destinationFolder + '/',
            )

        const newFiles = files.map(file => {
            return sources.includes(file.Key)
                ? evolve(
                      {
                          Key: moveRenamerFunction,
                          name: moveRenamerFunction,
                          loading: T,
                      },
                      file,
                  )
                : file
        })

        filesApi.setVals(newFiles)

        try {
            const results = (await api
                .post(
                    `/listing/${listingType}/${listing.listingId}/file/move`,
                    sources.map(source => ({
                        source,
                        destination: moveRenamerFunction(source),
                    })),
                )
                .then(prop('data'))) as {
                success: true
                source
                destination
            }[]

            if (results.some(x => !x.success)) {
                showSnack('Error moving files', 'danger')
            }

            filesApi.setVals(
                newFiles.map(file => {
                    const result = results.find(x => x.destination == file.Key)

                    console.log('file', file.Key)
                    console.log('result', result)

                    return {
                        ...file,
                        loading: result ? false : file.loading,
                        ...(result && !result.success
                            ? {
                                  Key: result.source,
                                  name: result.source.replace(/\/$/, ''),
                              }
                            : {}),
                    }
                }),
            )
        } catch (e) {
            console.log('error moving files', e)
            showSnack('Error moving files', 'danger')
            filesApi.setVals(backupFiles)
        }
        //await filesApi.refresh()
    }

    const fileEmail = `${listing.storageName}@storage${VITE_APP_STAGE == 'prod' ? '' : '.' + VITE_APP_STAGE}.fastre.com.au`

    return (
        <UploadFilesContext.Provider value={uploadFiles}>
            <MoveFilesContext.Provider value={moveFiles}>
                <Box
                    sx={{
                        height: '100%',
                        borderRadius: 'md',
                        m: -2,
                        p: 2,
                        position: 'relative',
                        ':hover': {
                            '> #deleteButton': {
                                opacity: 1,
                            },
                        },
                    }}
                >
                    <Sheet
                        variant="soft"
                        color="primary"
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            display: 'none',
                            p: 2,
                            borderRadius: 'sm',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <Typography>Upload Files</Typography>
                    </Sheet>
                    {filesApi.maybeData
                        .map(files => (
                            <Box>
                                {!formDisabled && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Typography>Attach emails by forwarding to {fileEmail}</Typography>
                                        <IconButton
                                            sx={{ ml: 1 }}
                                            onClick={() => copy(fileEmail)}
                                            size="sm"
                                        >
                                            <ContentCopy fontSize="small" />
                                        </IconButton>
                                    </Box>
                                )}
                                <DndContext
                                    sensors={sensors}
                                    onDragStart={({ active }) => {
                                        setActive(active)
                                    }}
                                    onDragEnd={async ({ active, over }) => {
                                        if (!over || !active || !activeItem) {
                                            return setActive(null)
                                        }

                                        //console.log({ active, over })

                                        const destinationFolder = (over.id as string).replace(/\/$/, '') // remove trailing slash

                                        const sourceParentFolder = activeItem.name
                                            .split('/')
                                            .slice(0, -1)
                                            .join('/')

                                        if (
                                            destinationFolder !== undefined &&
                                            sourceParentFolder !== destinationFolder &&
                                            active.id != over?.id &&
                                            !(
                                                activeItem!.isFolder &&
                                                destinationFolder.startsWith(activeItem!.name)
                                            )
                                        ) {
                                            moveFiles(activeItem, destinationFolder)
                                        }
                                        setActive(null)
                                    }}
                                    onDragCancel={() => {
                                        setActive(null)
                                    }}
                                >
                                    <FilesList
                                        setFile={setFile}
                                        formDisabled={formDisabled}
                                    >
                                        <DragOverlay>
                                            {activeItem && (
                                                <Box
                                                    sx={{
                                                        height: '36px',
                                                        width: '100%',
                                                        backgroundColor: theme.palette.background.level2,
                                                        py: 1,
                                                        px: 2,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                    }}
                                                >
                                                    {activeItem.isFolder ? (
                                                        <InsertDriveFileRounded />
                                                    ) : activeItem.isEmail ? (
                                                        <EmailRounded />
                                                    ) : (
                                                        <InsertDriveFileRounded />
                                                    )}
                                                    <Typography level="title-sm">
                                                        {activeItem.Key.split('/').pop()}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </DragOverlay>
                                    </FilesList>
                                </DndContext>
                                {!formDisabled && (
                                    <FileUploadButton
                                        sx={{ mt: 4 }}
                                        loading={loading}
                                        onChange={uploadFiles}
                                    >
                                        Upload File
                                    </FileUploadButton>
                                )}
                            </Box>
                        ))
                        .orSome(<Loading />)}
                    <Modal
                        open={maybeFile.filter(({ name }) => !name.endsWith('.pdf')).isSome()}
                        onClose={() => setFile(undefined)}
                    >
                        <ModalDialog sx={{ width: '100%' }}>
                            <ModalClose />
                            <DialogTitle>
                                {maybeFile.map(x => x.name.replaceAll('%2F', '/')).orUndefined()}
                            </DialogTitle>
                            <DialogContent>
                                {maybeFile
                                    .filter(({ name }) => !name.endsWith('.pdf'))
                                    .map(({ name, url }) =>
                                        name.endsWith('.eml') || name.endsWith('.msg') ? (
                                            <EmailModal {...{ name, url }} />
                                        ) : (
                                            <img
                                                src={url}
                                                alt={name}
                                                style={{ maxWidth: '100%' }}
                                            />
                                        ),
                                    )
                                    .orSome(<></>)}
                            </DialogContent>
                        </ModalDialog>
                    </Modal>
                    {/*<AddFolderModal
                    open={showAddFolder}
                    onClose={() => setShowAddFolder(false)}
                />*/}
                </Box>
                <PdfModal
                    title={maybeFile.map(({ name }) => name).orSome('')}
                    pdf={maybeFile
                        .filter(({ name }) => name.endsWith('.pdf'))
                        .map(prop('url'))
                        .orUndefined()}
                    onClose={() => setFile(undefined)}
                    fileName={maybeFile.map(prop('name')).orSome('')}
                />
            </MoveFilesContext.Provider>
        </UploadFilesContext.Provider>
    )
}

export default Files
