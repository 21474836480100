import { InternalLedgerSchema } from '@fastre/core/src/schemas/ledger'
import { ErrorRounded } from '@mui/icons-material'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalDialog,
    Stack,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { LedgerApiContext, useBulkLedgerApi } from 'apiProviders'
import Input from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useState } from 'react'

interface Props {
    ledgerItem?: InternalLedgerSchema
    setLedgerItem: (ledgerItem: InternalLedgerSchema | undefined) => void
    ledgerApi: Omit<LedgerApiContext, 'subscribe' | 'unsubscribe'>
}

export default function DisputeLedgerModal({ ledgerItem, setLedgerItem, ledgerApi }: Props) {
    const api = useApi()
    const bulkLedgerApi = useBulkLedgerApi(false)

    const [disputeReason, setDisputeReason] = useState('')
    const [loading, setLoading] = useState(false)

    const onClose = () => setLedgerItem(undefined)

    return (
        <Modal
            open={ledgerItem != undefined}
            onClose={dontCloseOnBackgroundClick(onClose)}
        >
            <ModalDialog>
                <DialogTitle>Dispute</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography>Please provide a reason for disputing this ledger entry.</Typography>
                        <Input
                            label="Reason"
                            value={disputeReason}
                            onChange={setDisputeReason}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="danger"
                        startDecorator={<ErrorRounded />}
                        loading={loading}
                        onClick={async () => {
                            setLoading(true)
                            await api.post(`/ledger/dispute`, {
                                id: ledgerItem!.id,
                                disputeReason,
                            })
                            await ledgerApi.refresh()
                            bulkLedgerApi.refresh()
                            setLoading(false)
                            onClose()
                        }}
                    >
                        Dispute
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
