import { extendTheme } from '@mui/joy/styles'
import { forwardRef } from 'react'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

const LinkBehavior = forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
    const { href, ...other } = props
    // Map href (Material UI) -> to (react-router)
    return (
        <RouterLink
            ref={ref}
            to={href}
            {...other}
        />
    )
})

export default extendTheme({
    colorSchemes: {
        dark: {
            palette: {
                background: {
                    body: '#121212',
                    surface: '#1E1E1E',
                },
            },
        },
    },
    components: {
        JoyTab: {
            styleOverrides: {
                root: {
                    gap: '20px',
                    borderTopLeftRadius: '12px', // Only round the top-left corner
                    borderTopRightRadius: '12px', // Only round the top-right corner
                    borderBottomLeftRadius: '0px', // No rounding at the bottom
                    borderBottomRightRadius: '0px', // No rounding at the bottom
                    padding: '8px 20px',
                    transition: 'background-color 0.3s ease',
                    '&.Mui-selected': {
                        backgroundColor: 'primary.50',
                        color: 'primary.600',
                    },
                    '&:hover': {
                        backgroundColor: 'grey.100',
                    },
                    whiteSpace: 'nowrap', // Prevent text wrapping
                    overflow: 'ellipse', // Hide overflow text
                    textOverflow: 'ellipsis', // Show ellipsis for overflowing text
                },
            },
        },
        JoyTabList: {
            styleOverrides: {
                root: {
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'background.paper',
                    zIndex: 1,
                    //borderBottom: '0.5px solid',
                    borderColor: 'divider',
                },
            },
        },
        JoyLink: {
            defaultProps: {
                component: LinkBehavior,
            },
        },
        JoyListItem: {
            defaultProps: {
                //component: LinkBehavior,
            },
        },
        JoyModalDialog: {
            styleOverrides: {
                root: ({ theme }) => ({
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                    },
                }),
            },
        },
        JoyTypography: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    fontFamily: 'Roboto, Arial, sans-serif',
                    color: theme.palette.mode === 'dark' ? 'grey.50' : 'grey.800', // Set default color based on mode
                    ...(ownerState.level === 'h4' && {
                        fontSize: '24px',
                        color: theme.palette.mode === 'dark' ? 'grey.300' : 'grey.900', // Set h4 color based on mode
                        marginTop: '24px',
                        marginBottom: '4px',
                        fontFamily: 'Roboto, Arial, sans-serif',
                    }),
                }),
            },
        },
        JoyFormControl: {
            styleOverrides: {
                root: {
                    width: '400px',
                    maxWidth: '100%',
                },
            },
        },

        /*JoyStack: {
            defaultProps: ({ theme }) => ({ spacing: theme.spacing(2) }),

            },
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    gap: theme.breakpoints.up('sm') ? 2 : 1,
                    direction: 'column',
                    [theme.breakpoints.up('sm')]: {
                        direction: 'row',
                    },
                    mt: 9,
                }),
            }
        },*/
    },
})
