import { BaseField, SelectionMarkField } from './azureOcr'
import { FormType as DocusignFormType } from './docusign'

const listingContacts = ['agent', 'vendors', 'buyers']
export type ListingContactType = (typeof listingContacts)[number]

export const FormTypes = [
    'agentAppointmentForm',
    'tenantConsentOpenHomeForm',
    'intentToSellForm' /*'contractForm'*/,
    'entryNoticeForm',
] as const
export type FormType = (typeof FormTypes)[number]

export const getFormName = (formType: FormType | DocusignFormType) => {
    switch (formType) {
        case 'agentAppointmentForm':
            return 'Agent Appointment Form'
        case 'disclosureStatementForm':
            return 'Disclosure Statement'
        case 'fundDisbursementForm':
            return 'Fund Disbursement Form'
        case 'contractForm':
            return 'Contract'
        case 'tenantConsentOpenHomeForm':
            return 'Tenant Consent for Open Home Form'
        case 'intentToSellForm':
            return 'Intent to Sell Form'
        case 'entryNoticeForm':
            return 'Entry Notice Form'
    }
}

export interface ReiSignatureRecipient {
    name: string
    email: string
    status: 'sent' | 'delivered' | 'completed'
}

export interface ReiSignatureStatus {
    status: 'sent' | 'delivered' | 'completed'
    created: string
    updated: string
    sent: string
    recipients: ReiSignatureRecipient[]
}

export interface ReiForm {
    id: number
    createdByUserId: string
    template_version_id?: number
    syncError?: boolean
    formStatus: 'draft' | 'finalised' | 'sent' | 'completed' | 'manual upload'
    signedForm?: string
    signatureRecipients?: ReiSignatureRecipient[]
    attachments: {
        name: string
        s3Key: string
    }[]
    ocr?: any
    azureOcr?: Record<string, SelectionMarkField | BaseField>
    ocr_gpt?: any
}

export interface FormToListingTable {
    orgId: string
    formType: FormType | 'contractForm'
    formId: 'string'
    listingId: 'string'
}
