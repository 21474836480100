import { Box } from '@mui/joy'
import Loading from 'components/Loading'
import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router'
import EditUser from '../users/editUser'
import LedgersView from './ledgersView'

const BulkUploadLedger = lazy(() => import('./bulkUploadLedger'))

export default function ManageLedgers() {
    return (
        <Routes>
            <Route
                path="bulkupload"
                element={
                    <Suspense fallback={<Loading />}>
                        <BulkUploadLedger />
                    </Suspense>
                }
            />
            <Route
                path=":userId"
                element={
                    <Box
                        sx={{
                            px: {
                                xs: 2,
                                sm: 4,
                            },
                        }}
                    >
                        <EditUser breadcrumbs={['Ledger Manager']} />
                    </Box>
                }
            />
            <Route
                index
                element={<LedgersView />}
            />
        </Routes>
    )
}
