import { insertIf } from '@fastre/core/src/helperFunctions/array'
import { capitalize } from '@fastre/core/src/helperFunctions/string'
import { InternalSaleListingSchema, listingEnabledForUser } from '@fastre/core/src/schemas/saleListing'
import { DeleteRounded, EditRounded, MoreVertRounded } from '@mui/icons-material'
import Save from '@mui/icons-material/Save'
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Dropdown,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    Modal,
    ModalClose,
    ModalDialog,
    Option,
    Select,
    Tab,
    tabClasses,
    TabList,
    Tabs,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from '@mui/joy'
import { useApi } from 'api'
import { ListingFilesProvider, ListingLedgerProvider, useListingType, useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { dontCloseOnBackgroundClick } from 'components/modal'
import SectionHead from 'components/sectionHead'
import ListingLedger from 'ledgerManager/listingLedger'
import { pick, pipe, prop } from 'ramda'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, Route, Routes, useMatch, useNavigate, useParams } from 'react-router'
import AuditLog from './auditLog'
import ConjunctionalAgreements from './conjunctionalAgreements'
import Contract from './contract'
import VoidContractModal from './contract/voidContractModal'
import CoreListingDetails from './coreListingDetails'
import DisclosureTab from './disclosureTab'
import { EditModeProvider, useEditMode } from './editMode'
import Files from './files'
import TrustFundTab from './fundsInTrust/trustFundTab'
import { listingStatusToColor, useListingStatusOptions } from './helpers'
import { ListingContext, useListingContext } from './listingProvider'
import MarketingTab from './marketingTab'
import tenantInfo from './tenantInfo'

const SaleListingDetails = forwardRef(({}, ref) => (
    <CoreListingDetails
        type="sale"
        ref={ref}
    />
))
const BdmListingDetails = () => <CoreListingDetails type="bdm" />

const ListingStatusChip = () => {
    const api = useApi()
    const listingType = useListingType()
    const { listing, setListing } = useListingContext()
    const listingStatusOptions = useListingStatusOptions(listing)

    const [loading, setLoading] = useState(false)

    if (loading) {
        return (
            <CircularProgress
                size="sm"
                sx={{ '--CircularProgress-size': '24px' }}
            />
        )
    }

    return (
        <Box>
            <Dropdown>
                <MenuButton
                    slots={{ root: Chip }}
                    slotProps={{
                        root: {
                            variant: 'soft',
                            color: listingStatusToColor(listing.listingStatus),
                            size: 'lg',
                            onClick: e => e.stopPropagation(),
                        },
                    }}
                >
                    {/* {listing.listingStatus.split(' ').map(capitalize).join(' ')*/}
                    {listing.listingStatus.split(' ').map(capitalize).join(' ')}
                </MenuButton>
                <Menu onClick={e => e.stopPropagation()}>
                    {listingStatusOptions.map(({ value, disabled }) => (
                        <MenuItem
                            key={value}
                            disabled={disabled}
                            onClick={async () => {
                                setLoading(true)
                                try {
                                    const updatedListing = (await api
                                        .post(`/listing/${listingType}/${listing.listingId}/status`, {
                                            listingStatus: value,
                                        })
                                        .then(prop('data'))) as InternalSaleListingSchema

                                    setListing({
                                        ...listing,
                                        ...pick(['listingStatus', 'balanceSheet'], updatedListing),
                                    })
                                } catch (e) {
                                    console.error(e)
                                } finally {
                                    setLoading(false)
                                }
                            }}
                        >
                            {capitalize(value)}
                        </MenuItem>
                    ))}
                </Menu>
            </Dropdown>
        </Box>
    )
}

const Inner = () => {
    const type = useListingType()
    const api = useApi()
    const navigate = useNavigate()
    const { listing } = useListingContext()
    const match = useMatch(`:orgId/listings/${type}/:listingId/:tab`)
    const theme = useTheme()
    const { user } = useUserData()
    const { listingId } = useParams<{ listingId: string }>()
    const { editMode, setEditMode } = useEditMode()
    const formRef = useRef<any>()
    const usersApi = useUsersApi()

    const isNew = listingId == 'new'

    const tabs = useMemo(
        () =>
            [
                ['Listing', type == 'sale' ? SaleListingDetails : BdmListingDetails],
                ['Marketing', MarketingTab],
                ...insertIf(type == 'sale', ['Conjunctional Agreements', ConjunctionalAgreements]),
                ...insertIf(listing.occupantStatus == 'investment', ['Tenancy Info', tenantInfo]),
                ...insertIf(
                    listing?.propertyType == 'cts' &&
                        (import.meta.env.VITE_APP_STAGE == 'uat' || import.meta.env.DEV),
                    ['Disclosure', DisclosureTab],
                ),
                ...insertIf(type == 'sale', ['Contract', Contract]),
                ...insertIf(type == 'sale' && user.permissions.includes('fit.view'), [
                    'Funds in Trust',
                    TrustFundTab,
                ]),
                ['Files', Files],
                /*...(isNew || usersApi.loading
                    ? []
                    : listing.agents?.map(({ userId }) => [
                          `Ledger${listing.agents.length > 1 ? ` (${getUserName(userId)})` : ''}`,
                          () => <ListingLedger userId={userId} />,
                      ])),*/
                ['Ledger', ListingLedger],
                ['Audit Log', AuditLog],
            ] as [string, () => JSX.Element][],
        [listing?.propertyType, type, usersApi.loading, listing?.occupantStatus],
    )

    const [showDeleteListing, setShowDeleteListing] = useState(false)
    const [voidModalOpen, setVoidModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [isDragging, setIsDragging] = useState(false)

    const activeTab = match?.params.tab

    const enabledForUser = listingEnabledForUser(listing, usersApi.data, user)
    const formFullyDisabled =
        !isNew &&
        !user.permissions.includes('listings.fullControl') &&
        !(enabledForUser && user.permissions.includes('listings.edit'))

    const [isMouseDown, setIsMouseDown] = useState(false)
    const [startX, setStartX] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsMouseDown(true)
        setIsDragging(false)
        setStartX(e.pageX - e.currentTarget.offsetLeft)
        setScrollLeft(e.currentTarget.scrollLeft)
    }
    const handleMouseLeave = () => setIsMouseDown(false)
    const handleMouseUp = () => setIsMouseDown(false)
    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isMouseDown) return
        e.preventDefault()
        const x = e.pageX - e.currentTarget.offsetLeft
        const walk = x - startX
        if (Math.abs(walk) > 5) {
            setIsDragging(true)
        }
        e.currentTarget.scrollLeft = scrollLeft - walk
    }

    // Scroll to active tab
    const tabListRef = useRef<HTMLDivElement>(null)
    const activeTabIndex = tabs.findIndex(([label]) => label.toLowerCase().replaceAll(' ', '-') == activeTab)
    useEffect(() => {
        if (!tabListRef.current || activeTabIndex < 0) return
        const tab = tabListRef.current.querySelector(`[role="tab"]:nth-child(${activeTabIndex + 1})`)
        if (!tab) return
        const tabListRect = tabListRef.current.getBoundingClientRect()
        const tabRect = (tab as HTMLElement).getBoundingClientRect()
        const offset = tabRect.left - tabListRect.left + tabListRef.current.scrollLeft
        const center = offset - tabListRect.width / 2 + tabRect.width / 2
        tabListRef.current.scrollTo({ left: center, behavior: 'smooth' })
    }, [activeTab])

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box>
                    <Box
                        sx={{
                            px: {
                                xs: 2,
                                sm: 4,
                            },
                        }}
                    >
                        <SectionHead
                            title={
                                isNew
                                    ? 'New Listing'
                                    : listing.listingAddress?.streetName
                                      ? `${(listing.listingAddress.unitNumber?.length ?? 0) > 0 ? listing.listingAddress.unitNumber + '/' : ''}${listing.listingAddress?.streetNumber} ${listing.listingAddress?.streetName}`
                                      : 'no street name'
                            }
                            titleChip={!isNew && <ListingStatusChip />}
                            breadcrumbs={[`${type == 'bdm' ? 'BDM ' : ''}Listings`]}
                            buttons={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 1, // Adjust spacing between buttons
                                        alignItems: 'center', // Vertical alignment
                                    }}
                                >
                                    {!formFullyDisabled && (
                                        <ToggleButtonGroup>
                                            {editMode ? (
                                                [
                                                    <Button
                                                        variant="solid"
                                                        color="primary"
                                                        startDecorator={<Save />}
                                                        loading={saving}
                                                        onClick={async () => {
                                                            setSaving(true)
                                                            const pass = await formRef.current?.saveForm()
                                                            if (pass) {
                                                                setEditMode(false)
                                                            }
                                                            setSaving(false)
                                                        }}
                                                    >
                                                        Save
                                                    </Button>,
                                                    <Button
                                                        variant="outlined"
                                                        loading={saving}
                                                        onClick={() => {
                                                            formRef.current?.resetForm()
                                                            setEditMode(false)
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>,
                                                ]
                                            ) : (
                                                <Button
                                                    onClick={() => setEditMode(true)}
                                                    variant="solid"
                                                    color="primary"
                                                    startDecorator={<EditRounded />}
                                                    disabled={formRef.current?.disabled}
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                            {user.permissions.includes('listings.delete') && (
                                                <Dropdown>
                                                    <MenuButton slots={{ root: IconButton }}>
                                                        <MoreVertRounded />
                                                    </MenuButton>
                                                    <Menu
                                                        sx={{
                                                            zIndex: 1205,
                                                        }}
                                                    >
                                                        <MenuItem
                                                            onClick={async () => setShowDeleteListing(true)}
                                                        >
                                                            Delete Listing
                                                        </MenuItem>
                                                        {user.permissions.includes('listings.fullControl') &&
                                                            activeTab == 'contract' && (
                                                                <MenuItem
                                                                    //startDecorator={<BlockRounded />}
                                                                    onClick={() => setVoidModalOpen(true)}
                                                                >
                                                                    Void Contract
                                                                </MenuItem>
                                                            )}
                                                    </Menu>
                                                </Dropdown>
                                            )}
                                        </ToggleButtonGroup>
                                    )}
                                </Box>
                            }
                        />
                    </Box>
                    <Box
                        sx={{
                            [theme.breakpoints.down('sm')]: {
                                display: 'none',
                            },
                        }}
                    >
                        <Tabs
                            value={tabs.findIndex(
                                ([label]) => label.toLowerCase().replaceAll(' ', '-') == activeTab,
                            )}
                            onChange={(event, val) => {
                                if (isDragging) {
                                    event?.preventDefault()
                                    event?.stopPropagation()
                                    return
                                }
                                if (val != undefined) {
                                    navigate(`./${tabs[val][0].toLowerCase().replaceAll(' ', '-')}`, {
                                        replace: true,
                                        relative: 'path',
                                    })
                                }
                            }}
                            sx={{ mx: 0 }}
                        >
                            <TabList
                                ref={tabListRef}
                                onMouseDown={handleMouseDown}
                                onMouseLeave={handleMouseLeave}
                                onMouseUp={handleMouseUp}
                                onMouseMove={handleMouseMove}
                                onClick={e => {
                                    if (isDragging) {
                                        e.preventDefault()
                                    }
                                }}
                                sx={{
                                    cursor: 'grab',
                                    //scrollBehavior: 'smooth',
                                    px: {
                                        xs: 2,
                                        sm: 4,
                                        overflow: 'auto',
                                        '&::-webkit-scrollbar': { display: 'none' },
                                    },
                                    [`& .${tabClasses.root}`]: {
                                        flex: 'none',
                                    },
                                }}
                            >
                                {tabs
                                    .filter((_, i) => !isNew || i <= 0)
                                    .map(([label], i) => (
                                        <Tab
                                            key={label}
                                            value={i}
                                        >
                                            {label}
                                        </Tab>
                                    ))}
                            </TabList>
                        </Tabs>
                    </Box>
                    <Box
                        sx={{
                            [theme.breakpoints.up('sm')]: {
                                display: 'none',
                            },
                        }}
                    >
                        <Select
                            value={activeTab}
                            onChange={(e, val) => {
                                if (val) {
                                    navigate(`./${val}`, {
                                        replace: true,
                                        relative: 'path',
                                    })
                                }
                            }}
                            sx={{
                                //width: '100%',
                                mx: 2,
                            }}
                        >
                            {tabs
                                .filter(([val], i) => !isNew || i == 0)
                                .map(([label], i) => (
                                    <Option
                                        key={label}
                                        value={label.toLowerCase().replaceAll(' ', '-')}
                                    >
                                        {label}
                                    </Option>
                                ))}
                        </Select>
                        <Divider sx={{ mt: 2 }} />
                    </Box>
                </Box>
                <Box
                    sx={{
                        px: {
                            xs: 2,
                            sm: 4,
                        },
                        mt: 4,
                        flex: '1 1 auto',
                        overflow: 'auto',
                    }}
                >
                    <Routes>
                        {(isNew ? ([tabs[0]] as any) : tabs).map(([label, Component], i) => (
                            <Route
                                key={label}
                                path={label.toLowerCase().replaceAll(' ', '-')}
                                element={<Component ref={formRef} />}
                            />
                        ))}
                        <Route
                            index
                            element={
                                <Navigate
                                    to={tabs[0][0].toLowerCase().replaceAll(' ', '-')}
                                    replace={true}
                                />
                            }
                        />
                    </Routes>
                </Box>
            </Box>
            <Modal
                open={showDeleteListing}
                onClose={dontCloseOnBackgroundClick(() => setShowDeleteListing(false))}
            >
                <ModalDialog>
                    <ModalClose />
                    <DialogTitle>Delete Listing</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to delete this listing?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="danger"
                            loading={loading}
                            onClick={async () => {
                                setLoading(true)
                                await api.delete(`/listing/${type}/${listingId}`)
                                setLoading(false)
                                navigate(`../`, { replace: true })
                            }}
                            startDecorator={<DeleteRounded />}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setShowDeleteListing(false)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
            <VoidContractModal
                open={voidModalOpen}
                onClose={voided => {
                    setVoidModalOpen(false)
                    if (voided) {
                        window.location.reload()
                    }
                }}
            />
        </>
    )
}

export default () => {
    const api = useApi()
    const { user } = useUserData()
    const { listingId } = useParams<{ listingId: string }>()
    const [listing, setListing] = useState<InternalSaleListingSchema | undefined>(undefined)
    const type = useListingType()

    const isNew = listingId == 'new'

    useEffect(() => {
        if (isNew) {
            setListing({
                listingStatus: 'offline',
                propertyType: 'residential',
                agents: [
                    {
                        userId: user.salesAssistantLeadAgentId ?? user.userId,
                        split: 1,
                    },
                ],
            } as any)
        } else {
            api.get(`/listing/${type}/${listingId}`).then(pipe(prop('data'), setListing))
        }
    }, [listingId])

    const refreshListing = () => api.get(`/listing/${type}/${listingId}`).then(pipe(prop('data'), setListing))

    if (!listing) {
        return <Loading />
    }

    return (
        <ListingContext.Provider value={{ listing, setListing: setListing as any, refreshListing }}>
            <EditModeProvider defaultEdit={isNew}>
                <ListingFilesProvider>
                    <ListingLedgerProvider>
                        <Inner />
                    </ListingLedgerProvider>
                </ListingFilesProvider>
            </EditModeProvider>
        </ListingContext.Provider>
    )
}
