import { useListingType } from 'apiProviders'
import { useUserData } from 'auth'
import axios from 'axios'
import { useListingContext } from 'listings/listingProvider'

export const apiUrl =
    import.meta.env.VITE_APP_API_URL ?? 'https://phxd1shyih.execute-api.ap-southeast-2.amazonaws.com'

export const API = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
})

export const useApi = () => {
    const { user } = useUserData()

    const token = localStorage.getItem('token')

    return axios.create({
        baseURL: apiUrl + (user ? `/${user.orgId}` : ''),
        //withCredentials: true,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const useListingApi = () => {
    const { user } = useUserData()
    const { listing } = useListingContext()
    const listingType = useListingType()

    const token = localStorage.getItem('token')

    return axios.create({
        baseURL: apiUrl + (user ? `/${user.orgId}/listing/${listingType}/${listing.listingId}` : ''),
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
