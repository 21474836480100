import z from 'zod'

export type InternalAgentStatement = {
    id: string
    orgId: string
    userId: string
    orgIdUserId: string
    date: number
    payrollId?: string
    unpaid: 'true' | undefined
    amount: number
    auditLog?: { userId: string; timestamp: number; details: string }[]
} & (
    | {
          type: 'debit'
          description: string
      }
    | ({ type: 'credit' } & (
          | ({
                creditType: 'listingCommission'
                listingId: string
            } & ({
                listingName: string
                totalCommissionAmountIncGst: number
                agentCommissionAmountIncSuper: number
                agentCommissionAmountExSuper: number
                conjunctionalAgreements: {
                    name: string
                    amount: number
                }[]
            } & (
                | { commissionOverride: true }
                | {
                      commissionOverride: false
                      salePrice: number
                      commissionPercentInc: number
                  }
            )))
          | {
                creditType: 'conjunctionalAgreement'
                listingId: string
                listingName: string
                amountIncGst: number
                agentCommissionAmountIncSuper: number
                agentCommissionAmountExSuper: number
            }
          | {
                creditType: 'ledgerCredit'
                description: string
            }
          | {
                creditType: 'manualCredit'
                description: string
            }
      ))
)

export type InternalAgentPayroll = {
    id: string
    orgId: string
    userId: string
    orgIdUserId: string
    runTime: number
    runBy: string
    paySlipNo: string
    paySlipDate: number
    totalAmount: number
}

export const AddManualAgentStatementSchema = z.object({
    userId: z.string(),
    type: z.enum(['debit', 'credit']),
    amount: z.number(),
    description: z.string().min(1),
    date: z.string().date(),
})

export type AddManualAgentStatementSchema = z.infer<typeof AddManualAgentStatementSchema>

export const getStatementDescription = (item: InternalAgentStatement): string => {
    switch (item.type) {
        case 'debit':
            return item.description
        case 'credit':
            switch (item.creditType) {
                case 'listingCommission':
                    return `Agent Commission - ${item.listingName}`
                case 'conjunctionalAgreement':
                    return `Conjunctional Agreement - ${item.listingName}`
                case 'ledgerCredit':
                    return item.description
                case 'manualCredit':
                    return item.description
            }
    }
}

export interface InternalAgentStatementAuditLogSchema {
    orgId: string
    userId: string
    orgIdUserId: string
    id: string
    timestamp: number
    action: string
    actionByUserId: string
    agentStatementId?: string
}
