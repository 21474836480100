import { toCurrency } from '@fastre/core/src/helperFunctions/string'
import { InternalLedgerSchema } from '@fastre/core/src/schemas/ledger'
import { EditRounded, ErrorRounded } from '@mui/icons-material'
import { Box, IconButton, Table, Tooltip, useTheme } from '@mui/joy'
import { LedgerApiContext, useFindUserFromId, useLedgerMonth } from 'apiProviders'
import { useUserData } from 'auth'
import { format } from 'date-fns'
import SortableHeader from 'listings/listingsTable/sortableHeader'
import { ascend, descend, sortWith, sum } from 'ramda'
import { useState } from 'react'
import DisputeLedgerModal from './disputeLedgerModal'

interface Props {
    rows: InternalLedgerSchema[]
    ledgerApi: Omit<LedgerApiContext, 'subscribe' | 'unsubscribe'>
    showUser?: boolean
}

export default function CoreLedgerTable({ rows, ledgerApi, showUser }: Props) {
    const theme = useTheme()
    const { user: userData } = useUserData()
    const { sort, setSort } = useLedgerMonth()
    const findUserFromId = useFindUserFromId()

    const [disputeItem, setDisputeItem] = useState<InternalLedgerSchema>()
    const [editRow, setEditRow] = useState<InternalLedgerSchema>()

    const balance = sum(rows.map(row => (row.credit ?? 0) - (row.debit ?? 0)))

    const sortFunc = sort.sortDirection == 'asc' ? ascend : descend
    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="ledgerDate"
                            >
                                Date
                            </SortableHeader>
                        </th>
                        <th>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="supplier"
                            >
                                Supplier
                            </SortableHeader>
                        </th>
                        <th>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="invoiceNumber"
                            >
                                Invoice Number
                            </SortableHeader>
                        </th>
                        <th>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="description"
                            >
                                Description
                            </SortableHeader>
                        </th>
                        <th>{showUser ? 'Agent' : 'Listing'}</th>
                        <th style={{ textAlign: 'right' }}>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="debit"
                            >
                                Debit
                            </SortableHeader>
                        </th>
                        <th style={{ textAlign: 'right' }}>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="credit"
                            >
                                Credit
                            </SortableHeader>
                        </th>
                        <th style={{ width: '48px' }} />
                        <th style={{ width: '54px' }} />
                    </tr>
                </thead>
                <tbody>
                    {sortWith([sortFunc(x => x[sort.sort] ?? '')], rows).map(row => (
                        <tr key={row.id}>
                            <td>{!row.monthlyCharge && format(new Date(row.ledgerDate), 'dd/MM/yyyy')}</td>
                            <td>{row.supplier}</td>
                            <td>{row.invoiceNumber}</td>
                            <td>{row.description}</td>
                            <td>
                                {showUser
                                    ? findUserFromId(row.userId).orUndefined()?.firstName
                                    : row.listingName}
                            </td>
                            <td style={{ textAlign: 'right' }}>{row.debit && toCurrency(row.debit)}</td>
                            <td style={{ textAlign: 'right' }}>{row.credit && toCurrency(row.credit)}</td>
                            <td>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'row',
                                    }}
                                >
                                    {userData.userId == row.userId && !row.approved && !row.disputeReason && (
                                        <IconButton
                                            size="sm"
                                            onClick={() => setDisputeItem(row)}
                                        >
                                            <ErrorRounded />
                                        </IconButton>
                                    )}
                                    {row.disputeReason && (
                                        <Tooltip title={row.disputeReason}>
                                            <ErrorRounded
                                                sx={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                                color={'danger' as any}
                                            />
                                        </Tooltip>
                                    )}
                                </Box>
                            </td>
                            <td>
                                {userData.permissions.includes('ledger.edit') &&
                                    (!row.approved || userData.superUser) && (
                                        <IconButton
                                            size="sm"
                                            onClick={() => setEditRow(row)}
                                        >
                                            <EditRounded />
                                        </IconButton>
                                    )}
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td
                            colSpan={5}
                            style={{ textAlign: 'right' }}
                        >
                            <b>Total</b>
                        </td>
                        <td style={{ textAlign: 'right' }}>
                            <b>{toCurrency(sum(rows.map(row => row.debit ?? 0)))}</b>
                        </td>
                        <td style={{ textAlign: 'right' }}>
                            <b>{toCurrency(sum(rows.map(row => row.credit ?? 0)))}</b>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td
                            colSpan={5}
                            style={{ textAlign: 'right', fontWeight: 'bold' }}
                        >
                            Balance
                        </td>
                        <td
                            style={{
                                textAlign: 'right',
                                color: theme.palette.danger[500],
                                fontWeight: 'bold',
                            }}
                        >
                            <b>{balance < 0 && toCurrency(-balance)}</b>
                        </td>
                        <td
                            style={{
                                textAlign: 'right',
                                color: theme.palette.success[500],
                                fontWeight: 'bold',
                            }}
                        >
                            {balance >= 0 && toCurrency(balance)}
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </Table>
            <DisputeLedgerModal
                ledgerItem={disputeItem}
                setLedgerItem={setDisputeItem}
                ledgerApi={ledgerApi}
            />
        </>
    )
}
