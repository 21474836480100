import { capitalize } from '@fastre/core/src/helperFunctions/string'
import { ownsListing } from '@fastre/core/src/schemas/listing'
import { UpdateConjunctionalAgreementSchema } from '@fastre/core/src/schemas/saleListing'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded } from '@mui/icons-material'
import { Box, Button, FormControl, FormLabel, IconButton, Stack, Switch, Table, Typography } from '@mui/joy'
import { TableContainer } from '@mui/material'
import { useApi } from 'api'
import { useFindUserFromId } from 'apiProviders'
import { useUserData } from 'auth'
import AgentInput from 'components/agentInput'
import { SlotInput } from 'components/input'
import { useShowSnack } from 'components/snackbar'
import { useIsMobile } from 'helperFunctions/pageSize'
import { dissoc, prop } from 'ramda'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import {
    Controller,
    FormProvider,
    SubmitHandler,
    useFieldArray,
    useForm,
    useFormContext,
} from 'react-hook-form'
import { useEditMode } from './editMode'
import { useListingContext } from './listingProvider'

const Line = ({
    index,
    removeAgreement,
    disabled,
}: {
    index: number
    removeAgreement: () => void
    disabled: boolean
}) => {
    const { watch, control, setValue } = useFormContext()
    const isMobile = useIsMobile()
    const { editMode } = useEditMode()

    const agreementType = watch(`conjunctionalAgreements.${index}.agreementType`)
    const type = watch(`conjunctionalAgreements.${index}.type`)

    const showLabels = isMobile || index == 0

    return (
        <>
            <Stack
                direction="row"
                gap={2}
            >
                <Controller
                    control={control}
                    disabled={false}
                    name={`conjunctionalAgreements.${index}.type`}
                    render={field => (
                        <FormControl sx={{ minWidth: '160px' }}>
                            {showLabels && <FormLabel>Type</FormLabel>}
                            {editMode ? (
                                <Switch
                                    {...dissoc('value', field.field)}
                                    checked={field.field.value == 'external'}
                                    onChange={e =>
                                        field.field.onChange(e.target.checked ? 'external' : 'internal')
                                    }
                                    startDecorator={({ checked }) => (
                                        <Typography
                                            level="body-sm"
                                            sx={{ opacity: !checked ? 1 : 0.35 }}
                                        >
                                            Internal
                                        </Typography>
                                    )}
                                    endDecorator={({ checked }) => (
                                        <Typography
                                            level="body-sm"
                                            sx={{ opacity: checked ? 1 : 0.35 }}
                                        >
                                            External
                                        </Typography>
                                    )}
                                />
                            ) : (
                                capitalize(field.field.value)
                            )}
                        </FormControl>
                    )}
                />
                {type == 'external' && (
                    <Controller
                        control={control}
                        disabled={disabled}
                        name={`conjunctionalAgreements.${index}.referrerName`}
                        render={field => (
                            <SlotInput
                                label={showLabels ? 'Referrer' : undefined}
                                {...field}
                            />
                        )}
                    />
                )}
                {type == 'internal' && (
                    <Controller
                        control={control}
                        disabled={disabled}
                        name={`conjunctionalAgreements.${index}.agentId`}
                        render={field => (
                            <AgentInput
                                {...field.field}
                                label={showLabels ? 'Referrer' : undefined}
                            />
                        )}
                    />
                )}
                <Controller
                    control={control}
                    disabled={disabled}
                    name={`conjunctionalAgreements.${index}.agreementType`}
                    render={field => (
                        <FormControl sx={{ minWidth: '160px', width: 'unset!important' }}>
                            {showLabels && <FormLabel>Agreement</FormLabel>}
                            {editMode ? (
                                <Switch
                                    {...dissoc('value', field.field)}
                                    checked={field.field.value == 'percentage'}
                                    onChange={e =>
                                        setValue(
                                            `conjunctionalAgreements.${index}.agreementType`,
                                            e.target.checked ? 'percentage' : 'fixed',
                                        )
                                    }
                                    startDecorator={({ checked }) => (
                                        <Typography
                                            level="body-sm"
                                            sx={{ opacity: !checked ? 1 : 0.35 }}
                                        >
                                            Fixed
                                        </Typography>
                                    )}
                                    endDecorator={({ checked }) => (
                                        <Typography
                                            level="body-sm"
                                            sx={{ opacity: checked ? 1 : 0.35 }}
                                        >
                                            Percentage
                                        </Typography>
                                    )}
                                />
                            ) : (
                                capitalize(field.field.value)
                            )}
                        </FormControl>
                    )}
                />
                {agreementType === 'fixed' && (
                    <Controller
                        control={control}
                        disabled={disabled}
                        name={`conjunctionalAgreements.${index}.commissionAmount`}
                        render={field => (
                            <SlotInput
                                label={showLabels ? 'Amount (inc. GST)' : undefined}
                                type="dollar"
                                {...field}
                            />
                        )}
                    />
                )}
                {agreementType === 'percentage' && (
                    <Controller
                        control={control}
                        disabled={disabled}
                        name={`conjunctionalAgreements.${index}.commissionPercent`}
                        render={field => (
                            <SlotInput
                                //label="Percentage"
                                label={showLabels ? 'Amount (inc. GST)' : undefined}
                                type="number"
                                endDecorator="%"
                                {...field}
                            />
                        )}
                    />
                )}
                {!disabled && editMode && (
                    <Box sx={{ display: 'flex' }}>
                        <IconButton
                            onClick={removeAgreement}
                            sx={{ mt: 'auto', mb: 1 }}
                        >
                            <DeleteRounded />
                        </IconButton>
                    </Box>
                )}
            </Stack>
        </>
    )
}

const ConjunctionalAgreement = forwardRef(({}, ref: any) => {
    const { listing, setListing } = useListingContext()
    const api = useApi()
    const showSnack = useShowSnack()
    const { user } = useUserData()
    const { editMode } = useEditMode()
    const findUserFromId = useFindUserFromId()

    const formDisabled =
        !user.permissions.includes('listings.fullControl') && !ownsListing(listing)(user.userId)

    const hookForm = useForm<UpdateConjunctionalAgreementSchema>({
        resolver: zodResolver(UpdateConjunctionalAgreementSchema),
        defaultValues: {
            conjunctionalAgreements: listing.conjunctionalAgreements ?? [
                {
                    type: 'external',
                    referrerName: '',
                    agreementType: 'fixed',
                    commissionAmount: 0,
                },
            ],
        },
    })

    const { control, handleSubmit, formState, getValues, setValue, trigger, reset, watch } = hookForm

    const {
        fields: conjunctionalAgreements,
        append: appendAgreement,
        remove: removeAgreement,
    } = useFieldArray({
        control,
        name: 'conjunctionalAgreements',
    })

    useEffect(() => {
        reset({
            conjunctionalAgreements: listing.conjunctionalAgreements,
        })
    }, [listing.listingId])

    const [submitLoading, setSubmitLoading] = useState(false)

    const submitForm: SubmitHandler<UpdateConjunctionalAgreementSchema> = async data => {
        setSubmitLoading(true)
        try {
            const updatedListingData = await api
                .post(`/listing/sale/${listing.listingId}/updateconjunctionalagreements`, data)
                .then(prop('data'))

            const updatedListing = { ...listing, ...updatedListingData }

            setListing(updatedListing)
            reset(updatedListing)
            showSnack('Conjunctional agreements updated', 'success')
            return true
        } catch (e) {
            console.error(e)
            showSnack('Error saving conjunctional agreements', 'danger')
        } finally {
            setSubmitLoading(false)
        }
    }

    useImperativeHandle(ref, () => ({
        saveForm: () => submitForm(getValues()),
        resetForm: () =>
            reset({
                conjunctionalAgreements: listing.conjunctionalAgreements,
            }),
        disabled: formDisabled,
    }))

    if (!editMode && (listing.conjunctionalAgreements?.length ?? 0) == 0) {
        return <Typography>No conjunctional agreements</Typography>
    }

    if (!editMode) {
        return (
            <TableContainer>
                <Table sx={{ maxWidth: '500px' }}>
                    <thead>
                        <tr>
                            <th>Refferer</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {conjunctionalAgreements.map(conj => (
                            <tr key={conj.id}>
                                <td>
                                    {conj.type == 'external'
                                        ? conj.referrerName
                                        : findUserFromId(conj.agentId)
                                              .map(x => `${x.firstName} ${x.lastName}`)
                                              .orSome('')}
                                </td>
                                <td>
                                    {conj.agreementType == 'fixed'
                                        ? `$${conj.commissionAmount}`
                                        : `${conj.commissionPercent}%`}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box>
            <FormProvider {...hookForm}>
                <form
                    noValidate
                    //onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack spacing={2}>
                        {conjunctionalAgreements.map((conj, index) => (
                            <Line
                                key={conj.id}
                                index={index}
                                removeAgreement={() => removeAgreement(index)}
                                disabled={formDisabled}
                            />
                        ))}
                    </Stack>
                    {!formDisabled && editMode && (
                        <>
                            <Button
                                variant="soft"
                                sx={{ mt: 2 }}
                                onClick={() =>
                                    appendAgreement({
                                        type: 'external',
                                        referrerName: '',
                                        agreementType: 'fixed',
                                        commissionAmount: 0,
                                    })
                                }
                            >
                                Add Agreement
                            </Button>
                        </>
                    )}
                </form>
            </FormProvider>
        </Box>
    )
})

export default ConjunctionalAgreement
