export const capitalize = (string: string | undefined) =>
    string && string.length > 0 ? string[0].toUpperCase() + string.substring(1) : ''

export const renderS = (list: any[]) => (list.length > 1 ? 's' : '')

export const toCurrency = (value: number) =>
    `$${value.toLocaleString('en-AU', { currency: 'AUD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}`

export const getUserFullName = (user: { firstName: string; lastName: string }) =>
    `${user.firstName} ${user.lastName}`
