import { Stack, StackProps, useTheme } from '@mui/joy'
import { Breakpoint } from '@mui/material'

interface MobileFriendlyStackProps extends StackProps {
    breakpoint?: Breakpoint
}

export const MobileFriendlyStack = ({ children, breakpoint, ...props }: MobileFriendlyStackProps) => {
    const theme = useTheme()

    return (
        <Stack
            gap={theme.breakpoints.up(breakpoint ?? 'sm') ? 2 : 1}
            {...props}
            sx={{
                display: 'flex',
                [theme.breakpoints.up(breakpoint ?? 'sm')]: {
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                },
                /*width: {
                    xs: '100%', // Full width on mobile screens
                    sm: '80%', // 50% width on larger screens
                    md: '70%', // 40% width on medium screens and above
                },
                maxWidth: {
                    md: '900px',
                },*/
                ...props.sx,
            }}
        >
            {children}
        </Stack>
    )
}
