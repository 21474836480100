import { capitalize } from '@fastre/core/src/helperFunctions/string'
import { VendorSchema, VendorType } from '@fastre/core/src/schemas/vendor'
import { DeleteRounded, WarningRounded } from '@mui/icons-material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'
import PhoneIcon from '@mui/icons-material/Phone'
import {
    Box,
    FormControl,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Sheet,
    Stack,
    Tooltip,
    Typography,
} from '@mui/joy'
import { SlotInput, SlotWrapper } from 'components/input'
import { MobileFriendlyStack } from 'components/stack'
import TextArea from 'components/textArea'
import { Controller, useFormContext } from 'react-hook-form'
import { useEditMode } from './editMode'

interface VendorListProps {
    name: string
    vendors: Array<VendorSchema & { id: string }>
    removeVendor: (index: number) => void
    vendorType: VendorType
    setVendorType: (type: VendorType) => void
    extraFields?: (vendor: VendorSchema, index: number) => React.ReactNode
    showOccupantStatus: boolean
    disabled?: boolean
    showAlert?: boolean
    hideTitle?: boolean
    showSensitiveInfo?: boolean
}

export default ({
    name,
    vendors,
    removeVendor,
    vendorType,
    setVendorType,
    extraFields,
    showOccupantStatus,
    disabled,
    hideTitle,
    showSensitiveInfo,
}: VendorListProps) => {
    const { control, setValue, watch, getValues } = useFormContext()
    const { editMode } = useEditMode()

    const fieldName = name.toLocaleLowerCase()
    const sensitiveInfo = watch('sensitiveInfo')

    return (
        <>
            <Stack
                direction="row"
                sx={{
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                {!hideTitle && <Typography level="h4">{name}s</Typography>}
                {(sensitiveInfo?.replaceAll(' ', '').length ?? 0) > 0 && (
                    <Tooltip title={sensitiveInfo}>
                        <WarningRounded
                            fontSize={'lg' as any}
                            color="warning"
                        />
                    </Tooltip>
                )}
            </Stack>
            {showSensitiveInfo && (
                <Controller
                    name="sensitiveInfo"
                    control={control}
                    //disabled={formFullyDisabled}
                    render={field => (
                        <SlotWrapper
                            label="Sensitive Information"
                            {...field}
                        >
                            <TextArea {...field.field} />
                        </SlotWrapper>
                    )}
                />
            )}
            {editMode && (
                <MobileFriendlyStack>
                    <FormControl sx={{ flex: '1' }}>
                        <FormLabel>{name} Type</FormLabel>
                        {editMode ? (
                            <RadioGroup
                                orientation="horizontal"
                                value={vendorType}
                                onChange={e => {
                                    setVendorType(e.target.value as any)
                                    vendors.forEach((_, i) => {
                                        setValue(`${fieldName}s.${i}.vendorType`, e.target.value as any)
                                    })
                                }}
                            >
                                <Radio
                                    value="individual"
                                    label="Individual"
                                    disabled={disabled}
                                />
                                <Radio
                                    value="company"
                                    label="Company"
                                    disabled={disabled}
                                />
                            </RadioGroup>
                        ) : (
                            <Typography>{capitalize(vendorType)}</Typography>
                        )}
                    </FormControl>
                </MobileFriendlyStack>
            )}
            <MobileFriendlyStack>
                {showOccupantStatus && vendorType == 'individual' && (
                    <Controller
                        name="occupantStatus"
                        control={control}
                        render={field => (
                            <FormControl sx={{ flex: '1' }}>
                                <FormLabel>Occupant Status</FormLabel>
                                {editMode ? (
                                    <RadioGroup
                                        orientation="horizontal"
                                        {...field.field}
                                        onChange={e => field.field.onChange(e.target.value)}
                                    >
                                        <Radio
                                            value="owner occupied"
                                            label="Owner Occupied"
                                            disabled={disabled}
                                        />
                                        <Radio
                                            value="investment"
                                            label="Tenanted"
                                            disabled={disabled}
                                        />
                                    </RadioGroup>
                                ) : (
                                    <Typography>{capitalize(field.field.value)}</Typography>
                                )}
                            </FormControl>
                        )}
                    />
                )}
            </MobileFriendlyStack>

            {!editMode && (
                <Stack gap={2}>
                    {vendors.map((vendor, i) => (
                        <Sheet
                            key={vendor.id}
                            variant="outlined"
                            sx={{
                                p: 2,
                                borderRadius: 'md',
                                border: 'none',
                                //width: '50%', // Adjust width as needed
                                maxWidth: 400,
                            }}
                        >
                            <Stack
                                //direction="row"
                                sx={{ mb: 2, justifyContent: 'space-between', border: 'none' }}
                            >
                                {vendorType == 'company' && (
                                    <>
                                        {/* Company Name */}
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            mb={1}
                                        >
                                            <BusinessCenterIcon sx={{ mr: 1 }} />
                                            <Typography fontWeight="bold">
                                                {getValues(`${fieldName}s.${i}.companyName`)}
                                            </Typography>
                                        </Box>

                                        {/* ABN */}
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            mb={1}
                                        >
                                            <Typography
                                                level="body-sm"
                                                fontWeight="bold"
                                                sx={{ mr: 1 }}
                                            >
                                                ABN:
                                            </Typography>
                                            <Typography level="body-sm">
                                                {getValues(`${fieldName}s.${i}.abn`)}
                                            </Typography>
                                        </Box>

                                        {/* ACN */}
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            mb={1}
                                        >
                                            <Typography
                                                level="body-sm"
                                                fontWeight="bold"
                                                sx={{ mr: 1 }}
                                            >
                                                ACN:
                                            </Typography>
                                            <Typography level="body-sm">
                                                {getValues(`${fieldName}s.${i}.acn`)}
                                            </Typography>
                                        </Box>
                                    </>
                                )}

                                <Box
                                    display="flex"
                                    alignItems="center"
                                    mb={2}
                                ></Box>
                                {/*  Name */}
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                >
                                    <AccountCircleIcon sx={{ mr: 1 }} />
                                    <Typography fontWeight="bold">
                                        {vendor.firstName || vendor.lastName
                                            ? `${vendor.firstName ?? ''} ${vendor.lastName ?? ''}`.trim()
                                            : 'No name available'}
                                    </Typography>
                                </Box>

                                {/* Email */}
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                >
                                    <EmailIcon sx={{ mr: 1 }} />
                                    <Typography
                                        level="body-sm"
                                        component="a"
                                        href={vendor.email ? `mailto:${vendor.email}` : '#'}
                                        sx={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        {vendor.email ? vendor.email : 'No email available'}
                                    </Typography>
                                </Box>

                                {/* Phone */}
                                <Box
                                    display="flex"
                                    alignItems="center"
                                >
                                    <PhoneIcon sx={{ mr: 1 }} />
                                    <Typography
                                        level="body-sm"
                                        component="a"
                                        href={vendor.mobileNumber ? `tel:${vendor.mobileNumber}` : '#'}
                                        sx={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        {vendor.mobileNumber
                                            ? vendor.mobileNumber
                                            : 'No phone number available'}
                                    </Typography>
                                </Box>

                                {vendor.address && (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <HomeIcon sx={{ mr: 1 }} />
                                        <Typography fontWeight="bold">vendor.Address</Typography>
                                    </Box>
                                )}
                            </Stack>
                        </Sheet>
                    ))}
                </Stack>
            )}

            {editMode && (
                <Stack gap={2}>
                    {vendors.map((vendor, i) => (
                        <Sheet
                            key={vendor.id}
                            variant="outlined"
                            sx={{
                                p: 2,
                                borderRadius: 'md',
                                border: 'none',
                                //width: '50%', // Adjust width as needed
                                width: '600px',
                                maxWidth: '100%',
                            }}
                        >
                            <Stack
                                direction="row"
                                sx={{ mb: 2, justifyContent: 'space-between', border: 'none' }}
                            >
                                <Typography fontWeight="bold">
                                    {capitalize(name)} {i + 1}
                                </Typography>
                                {!disabled && editMode && (
                                    <Box>
                                        <IconButton
                                            variant="outlined"
                                            onClick={() => removeVendor(i)}
                                            size="sm"
                                        >
                                            <DeleteRounded fontSize="small" />
                                        </IconButton>
                                    </Box>
                                )}
                            </Stack>
                            <Stack gap={2}>
                                {vendorType == 'company' && (
                                    <>
                                        <Controller
                                            name={`${fieldName}s.${i}.companyName`}
                                            disabled={disabled}
                                            control={control}
                                            render={field => (
                                                <SlotInput
                                                    label="Company Name"
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <MobileFriendlyStack
                                            gap={2}
                                            sx={{ width: '100%' }}
                                        >
                                            <Controller
                                                name={`${fieldName}s.${i}.abn`}
                                                disabled={disabled}
                                                control={control}
                                                render={field => (
                                                    <SlotInput
                                                        label="ABN"
                                                        {...field}
                                                        formControlProps={{
                                                            sx: { flex: 1 },
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name={`${fieldName}s.${i}.acn`}
                                                disabled={disabled}
                                                control={control}
                                                render={field => (
                                                    <SlotInput
                                                        label="ACN"
                                                        {...field}
                                                        formControlProps={{
                                                            sx: { flex: 1 },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </MobileFriendlyStack>
                                    </>
                                )}
                                <MobileFriendlyStack
                                    gap={2}
                                    sx={{ width: '100%' }}
                                >
                                    <Controller
                                        name={`${fieldName}s.${i}.firstName`}
                                        disabled={disabled}
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                label="First Name"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`${fieldName}s.${i}.lastName`}
                                        disabled={disabled}
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                label="Last Name"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                            />
                                        )}
                                    />
                                </MobileFriendlyStack>
                                <MobileFriendlyStack
                                    sx={{ width: '100%' }}
                                    gap={2}
                                >
                                    <Controller
                                        name={`${fieldName}s.${i}.email`}
                                        disabled={disabled}
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                label="Email"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`${fieldName}s.${i}.mobileNumber`}
                                        disabled={disabled}
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                label="Phone Number"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                            />
                                        )}
                                    />
                                </MobileFriendlyStack>
                                {extraFields && extraFields(vendor, i)}
                            </Stack>
                        </Sheet>
                    ))}
                </Stack>
            )}
        </>
    )
}
